import AuthLayout from "../layouts/Auth.vue";
import Dashboard from "../layouts/Dashboard.vue";
import LoggedOut from "../layouts/LoggedOut.vue";
import Login from "../views/Pages/Login.vue";
import RecoverPassword from "../views/Pages/RecoverPassword.vue";
import ConfirmData from "../views/Pages/ConfirmData.vue";
import CreatePassword from "../views/Pages/CreatePassword.vue";
import HomeDashboard from "../views/Pages/Dashboard/Home.vue";
import PageNotFound from "../views/Pages/PageNotFound.vue";
import AccessDenied from "../views/Pages/AccessDenied.vue";
import DataConfirmation from "../views/Pages/DataConfirmation.vue";
import CompleteRegister from "../views/Pages/CompleteRegister.vue";
import LoggedOutAssemblyVote from "../views/Pages/LoggedOut/AssemblyVote.vue";
import VoteNotAvailable from "../views/Pages/VoteNotAvailable.vue";
import AnnouncementListing from "../views/Pages/Dashboard/Announcement/Listing.vue";
import AnnouncementDetail from "../views/Pages/Dashboard/Announcement/Detail.vue";

import TermsAndConditionsOfUse from "@/views/Pages/TermsAndConditionsOfUse.vue";
import PrivacyPolicy from "@/views/Pages/PrivacyPolicy.vue";
import AssemblyListing from "../views/Pages/Dashboard/Assembly/Listing.vue";
import ShareholderVote from "@/views/Pages/Dashboard/Assembly/ShareholderVote.vue";

import Version from "@/views/Pages/Version.vue";
import Status from "@/views/Pages/Status.vue";

const routes = [
  {
    path: "/",
    name: "dashboard",
    meta: { requiresAuth: true },
    component: Dashboard,
    children: [
      {
        name: "home",
        path: "/",
        meta: { title: "Dashboard" },
        component: HomeDashboard,
        redirect: { name: "assembly" },
      },
      {
        name: "assembly",
        path: "assembly",
        redirect: { name: "assembly-list" },
        children: [
          {
            name: "assembly-list",
            path: "list",
            component: AssemblyListing,
            meta: {
              title: "Assembleias",
              transition: "slide-left",
            },
          },
          {
            name: "assembly-detail",
            path: "detail/:id",
            component: ShareholderVote,
            meta: {
              title: "Ver assembleia",
              transition: "slide-left",
              previousRoute: "assembly-list",
            },
          },
        ],
      },
      {
        name: "announcements",
        path: "announcements",
        redirect: { name: "announcements-list" },
        children: [
          {
            name: "announcements-list",
            path: "list",
            component: AnnouncementListing,
            meta: {
              title: "Comunicados",
              transition: "slide-left",
            },
          },
          {
            name: "announcements-detail",
            path: "detail/:id/:fund",
            component: AnnouncementDetail,
            meta: {
              title: "Ver comunicado",
              transition: "slide-left",
              previousRoute: "announcements-list",
            },
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    name: "auth",
    component: AuthLayout,
    redirect: { name: "user-login" },
    meta: {
      title: "Autenticação",
      meta: { title: "Buckets" },
    },
    children: [
      {
        name: "user-login",
        path: "login",
        component: Login,
        meta: { title: "Login", transition: "slide-left" },
      },
      {
        path: "recover-password",
        component: RecoverPassword,
        meta: { title: "Recuperar Senha", transition: "slide-left" },
      },
      {
        path: "first-access",
        component: ConfirmData,
        meta: { title: "Confirme seus dados", transition: "slide-left" },
      },
      {
        path: "register-password",
        component: CreatePassword,
        meta: { title: "Criar Senha", transition: "slide-left" },
      },
      {
        path: "complete-register",
        component: CompleteRegister,
        meta: { title: "Completar cadastro", transition: "slide-left" },
      },
    ],
  },
  {
    path: "/",
    name: "terms",
    component: AuthLayout,
    meta: {
      title: "",
    },
    children: [
      {
        name: "termos-e-condicoes-de-uso",
        path: "termos-e-condicoes-de-uso",
        component: TermsAndConditionsOfUse,
        meta: { title: "Termos e Condições de Uso", transition: "slide-left" },
      },
      {
        name: "politica-de-privacidade",
        path: "politica-de-privacidade",
        component: PrivacyPolicy,
        meta: { title: "Política de Privacidade", transition: "slide-left" },
      },
    ],
  },

  {
    path: "/logged-out",
    name: "logged-out",
    component: LoggedOut,
    redirect: { name: "user-login" },
    children: [
      {
        name: "logged-out-assembly-vote",
        path: "assembly-vote",
        component: LoggedOutAssemblyVote,
        meta: { title: "Assembleia", transition: "slide-left" },
      },
    ],
  },
  {
    path: "/404",
    meta: { title: "Página não encontrada" },
    component: PageNotFound,
  },
  {
    path: "/access-denied",
    meta: { title: "Acesso não autorizado" },
    component: AccessDenied,
  },
  {
    path: "/data-confirmation",
    meta: { title: "Confirmação de dados" },
    component: DataConfirmation,
  },
  {
    path: "/vote-not-available",
    name: "vote-not-available",
    meta: { title: "Voto não disponível" },
    component: VoteNotAvailable,
  },
  { path: "/:pathMatch(.*)", redirect: "/404" },
  {
    path: "/version",
    meta: { title: "App version" },
    component: Version,
  },
  {
    path: "/health-check",
    meta: { title: "Services status" },
    component: Status,
  },
];

export default routes;
