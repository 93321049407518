<template>
  <div class="row">
    <div class="col-12">
      <SearchInput
        placeholder="Nome do fundo"
        @search="handleSearch"
        :value="search"
      />
    </div>
  </div>
  <div v-if="itemsAssembly.length < 1 && !isSearch" class="row mt-4">
    <div class="text-center pt-4">
      <img
        class="empty-img py-3"
        src="@/assets/img/assembly-list-clean.svg"
        alt=""
        srcset=""
      />
      <p class="text-black fw-400">Nenhuma assembleia pendente para votação!</p>
    </div>
  </div>
  <div v-else class="row">
    <Table :headers="headerAssembly" :items="itemsAssembly">
      <FilterTable
        ref="filterTable"
        :filters="arrFilters"
        @applyFilter="applyFilter"
      />
    </Table>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end">
      <Pagination
        :numberPage="numberPage"
        routeName="assembly-list"
        :currentPage="currentPage"
      />
    </div>
  </div>
</template>
<script>
import SearchInput from "@/components/Custom/SearchInput.vue";
import Table from "@/components/Custom/Table.vue";
import FilterTable from "@/components/Custom/FilterTable.vue";
import Pagination from "@/components/Pagination/Pagination.vue";
import assemblyService from "@/services/assembly-service";
import { dateFormatter, statusFromAssembly } from "@/utils/utils";

export default {
  name: "page-assembleia",
  components: {
    SearchInput,
    Table,
    FilterTable,
    Pagination,
  },
  data() {
    return {
      statusEnable: statusFromAssembly(),
      isSearch: false,
      search: "",
      headerAssembly: [
        {
          text: "Nome do fundo",
          value: "fundName",
          tooltip: true,
        },
        {
          text: "Prazo da votação",
          value: "assemblyDate",
          type: "date-iso",
        },
        {
          text: "Situação do voto",
          value: "votingStatus",
          type: "icon",
        },
        {
          text: "Situação assembleia",
          value: "assemblyStatus",
          type: "icon",
        },
        {
          text: "Ações",
          value: "actions",
          align: "rigth",
          type: "action",
        },
      ],
      itemsAssembly: [],
      numberPage: 0,
      currentPage: 1,
      arrFilters: [
        {
          title: "Situação voto",
          value: "voted",
          type: "radio",
          options: [
            {
              title: "Todos",
              value: "",
              selected: true,
            },
            {
              title: "Não realizado",
              value: "false",
            },
            {
              title: "Realizado",
              value: "true",
            },
          ],
        },
        {
          title: "Situação assembleia",
          value: "status",
          type: "checkbox",
          options: [
            {
              title: "Em andamento",
              value: "Em andamento",
            },
            {
              title: "Apuração pendente",
              value: "Pendente de apuração",
            },
            {
              title: "Concluída",
              value: "Concluída",
            },
            {
              title: "Suspensa",
              value: "Suspensa",
            },
            {
              title: "Cancelada",
              value: "Cancelada",
            },
          ],
        },
        {
          title: "Período votação",
          value: "date",
          type: "date",
          options: [
            {
              title: "De:",
              value: "ofDate",
            },
            {
              title: "Até:",
              value: "toDate",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    if (this.$route.query.fundName) {
      this.search = this.$route.query.fundName;
    }
    this.getAllAssembly();
  },
  methods: {
    handleSearch(value) {
      this.search = value;
      if (value && this.$refs.filterTable) {
        if (typeof this.$refs.filterTable.applyFilter === "function") {
          this.$refs.filterTable.applyFilter();
        }
      } else {
        this.applyFilter({});
      }
    },
    getAllAssembly() {
      assemblyService
        .getAll(this.$route.query)
        .then((response) => {
          this.numberPage = response.totalPage;
          this.currentPage = response.page;
          this.createListAssembly(response.results);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    createListAssembly(results) {
      const list = [];
      results.forEach((result) => {
        const element = result.assembly;
        const object = {
          id: element._id,
          fundName: element.fund.name,
          fundCnpj: element.fund.cnpj,
          assemblyDate: element.votingEndDate,
          assemblyStatus: this.getIconAssemblyStatus(element.status),
          votingStatus: {
            text: result.voted ? "Realizado" : "Não realizado",
            icon: result.voted
              ? "fa-regular fa-circle-check"
              : "fa-regular fa-clock",
            color: result.voted
              ? "var(--color-success)"
              : "var(--color-primary)",
          },
          actions: [
            {
              color: "var(--color-primary)",
              title:
                element.status != this.statusEnable.andamento
                  ? "Visualizar"
                  : "Votar",
              icon:
                element.status != this.statusEnable.andamento
                  ? "fa-regular fa-eye"
                  : "fa-regular fa-check-to-slot",
              onClick: (item) => {
                this.$router.push({
                  name: "assembly-detail",
                  params: {
                    id: item.id,
                  },
                });
              },
            },
          ],
        };
        list.push(object);
      });
      this.itemsAssembly = list;
    },
    dateFormat(date) {
      if (date) {
        return dateFormatter(date);
      }
      return date;
    },
    getIconAssemblyStatus(status) {
      switch (status) {
        case "Suspensa":
          return {
            text: "Suspensa",
            icon: "fa-regular fa-circle-xmark",
            color: "var(--color-danger)",
          };
        case "Cancelada":
          return {
            text: "Cancelada",
            icon: "fa-regular fa-ban",
            color: "var(--color-danger)",
          };
        case "Concluída":
          return {
            text: "Concluída",
            icon: "fa-regular fa-circle-check",
            color: "var(--color-success)",
          };
        case "Em andamento":
          return {
            text: "Em andamento",
            icon: "fa-regular fa-clock",
            color: "var(--color-info)",
          };
        case "Cadastrada":
          return {
            text: "Cadastrada",
            icon: "fa-regular file-check",
          };
        case "Pendente de apuração":
          return {
            text: "Apuração pendente",
            icon: "fa-regular fa-clock-rotate-left",
            color: "var(--color-info)",
          };
        default:
          return {
            text: status,
          };
      }
    },
    applyFilter(obj) {
      if (this.search) {
        obj.fundName = this.search;
      }
      if (Object.keys(obj).length > 0) {
        this.isSearch = true;
      } else {
        this.isSearch = false;
      }
      if (this.$route.query.page) {
        obj.page = this.$route.query.page;
      }
      if (this.$route.query.limit) {
        obj.limit = this.$route.query.limit;
      }
      this.$router.replace({
        name: "assembly-list",
        query: obj,
      });
    },
  },
  watch: {
    $route(to) {
      if (to.name == "assembly-list") {
        if (this.$route.query.fundName) {
          this.search = this.$route.query.fundName;
        }
        this.getAllAssembly();
      }
    },
  },
};
</script>
