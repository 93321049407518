import api from "./api";

const MICRO_SERVICE_URL_ASSEMBLY = "/assembleia";

class AssemblyService {
  async getAll(queryparams) {
    const response = await api.get(
      `${MICRO_SERVICE_URL_ASSEMBLY}/api/shareholder/assemblies`,
      { params: queryparams }
    );
    return response.data;
  }

  async validateVotingToken(votingToken, cpfCnpj) {
    const headers = { dontUseBearerToken: true };
    const response = await api.post(
      `${MICRO_SERVICE_URL_ASSEMBLY}/api/vote/validate-voting-token`,
      { votingToken, cpfCnpj },
      { headers }
    );
    return response.data;
  }

  async getAssemblyByVotingToken(votingToken) {
    const tokenParts = votingToken.split(".");
    const payload = JSON.parse(atob(tokenParts[1]));
    const idAssembly = payload.sub.split(":")[0];
    const response = await api.get(
      `${MICRO_SERVICE_URL_ASSEMBLY}/api/shareholder/assemblies/${idAssembly}`
    );
    return response.data;
  }

  async registerVote(assemblyId, votes) {
    const headers = { sendExternalIpAddress: true };
    const response = await api.post(
      `${MICRO_SERVICE_URL_ASSEMBLY}/api/vote/submit`,
      { assemblyId, votes },
      { headers }
    );
    return response.data;
  }

  async getAssemblyById(idAssembly) {
    const response = await api.get(
      `${MICRO_SERVICE_URL_ASSEMBLY}/api/shareholder/assemblies/${idAssembly}`
    );
    return response.data;
  }

  async getStatusAssemblyByVotingToken(token) {
    const headers = { dontUseBearerToken: true };
    const response = await api.get(
      `${MICRO_SERVICE_URL_ASSEMBLY}/api/shareholder/assembly-by-token`,
      { params: { token }, headers }
    );
    return response.data;
  }

  async getAnnoucementByIdAssembly(assemblyId) {
    const response = await api.get(
      `${MICRO_SERVICE_URL_ASSEMBLY}/api/communicate?assemblyId=${assemblyId}`
    );
    return response.data;
  }
}

export default new AssemblyService();
