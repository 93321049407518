export function base64decodetoJson(data) {
  var object = JSON.parse(atob(data));
  return object;
}

export function base64encodetoJson(data) {
  var object = btoa(data);
  return object;
}

export function checkExpiration(unixTimestamp) {
  var current_data = new Date().getTime();
  const milliseconds = unixTimestamp * 1000;
  if (current_data > milliseconds) {
    return false;
  }
  return true;
}

export function generateRandomPassword() {
  var chars =
    "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  var passwordLength = 12;
  var password = "";
  for (var i = 0; i <= passwordLength; i++) {
    var randomNumber = Math.floor(Math.random() * chars.length);
    password += chars.substring(randomNumber, randomNumber + 1);
  }
  return password;
}

export function handleErrorMessage(error) {
  if (error) {
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.messages) {
          return error.response.data.messages;
        } else {
          return "internal error";
        }
      }
    } else if (error.message) {
      return error.message;
    } else {
      return "internal error";
    }
  }
}

function replaceMessageWithUnderscore(str) {
  return str
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "_")
    .replace(/^-+|-+$/g, "");
}

export function getMessagesToTranslatePlugin(messagesArray, te = null) {
  var array,
    arr = [];
  if (!messagesArray) {
    return "error";
  }
  if (!Array.isArray(messagesArray)) {
    array = messagesArray.split(",");
  } else {
    array = messagesArray;
  }
  array.forEach((element) => {
    var slug = replaceMessageWithUnderscore(element);
    if (te && te(slug)) {
      arr.push(slug);
    } else {
      arr.push(element);
    }
  });
  return arr;
}

export function translateArray(arrKeys, vue) {
  var arrayTranslate = [];
  arrKeys.forEach((element) => {
    arrayTranslate.push(vue.$translate(element));
  });
  return arrayTranslate;
}

export function generateMessage(message, type, vue) {
  switch (type) {
    case "SUCCESS":
      vue.$toast.success(message, {
        position: "top-right",
      });
      break;
    case "ERROR":
      if (Array.isArray(message)) {
        var html = "<div>";
        message.map((item) => {
          html += `${item}<br>`;
        });
        html += "</div>";
        vue.$toast.error(html, {
          position: "top-right",

          queue: false,
          maxToasts: 1,
        });
      } else {
        vue.$toast.error(message, {
          position: "top-right",
          queue: false,
          maxToasts: 1,
        });
      }
      break;
    case "INFO":
      vue.$toast.info(message, {
        position: "top-right",
      });
      break;
    default:
      break;
  }
}

export function formatCpfCnpj(text) {
  if (text.length <= 11) {
    return text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
  }
  return text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
}
export function dateFormatter(date, type) {
  var datahours = new Date(date);
  var formatted_time = new Date(date);
  formatted_time = `${
    formatted_time.getDate() < 10
      ? "0" + formatted_time.getDate()
      : formatted_time.getDate()
  }/${
    formatted_time.getMonth() + 1 < 10
      ? "0" + (formatted_time.getMonth() + 1)
      : formatted_time.getMonth() + 1
  }/${formatted_time.getFullYear()}`;
  var hours = datahours.getHours();
  hours = hours >= 0 && hours <= 9 ? "0" + hours : hours;
  var minutes = datahours.getMinutes();
  minutes = minutes >= 0 && minutes <= 9 ? "0" + minutes : minutes;
  if (type == "time") {
    formatted_time = formatted_time + " " + hours + ":" + minutes;
  }
  if (type == "only_time") {
    formatted_time = hours + ":" + minutes;
  }
  return formatted_time;
}

export function dateIsValid(date) {
    return date instanceof Date && !isNaN(date);
}

export function formattedNumber(number) {
  if (number) {
    return number.toLocaleString("pt-BR", {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
  }
}
export function generateMessagev2(type, vue, msgORerror) {
  if (type == "SUCCESS") {
    generateMessage(msgORerror, type, vue);
  } else {
    var arrKeys = getMessagesToTranslatePlugin(handleErrorMessage(msgORerror));
    var arrTranslates = translateArray(arrKeys, vue);
    generateMessage(arrTranslates, type, vue);
  }
}

export function statusFromAssembly() {
  return {
    concluida: "Concluída",
    pendente: "Pendente de apuração",
    cancelada: "Cancelada",
    suspensa: "Suspensa",
    andamento: "Em andamento",
    cadastrada: "Cadastrada",
  };
}

export function checkExpirationTokenExternal(token) {
  var exp = "";
  try {
    var token_part = token.access_token.split(".")[1];
    exp = base64decodetoJson(token_part).exp;
  } catch (error) {
    return false;
  }

  var current_data = new Date().getTime();
  const milliseconds = exp * 1000;
  if (current_data > milliseconds) {
    return false;
  }
  return true;
}
