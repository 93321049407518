<template>
  <div class="row">
    <div class="col-md-4 col-sm-6 col-xs-12">
      <SearchInput
        placeholder="Assunto do comunicado"
        @search="searchInputEmit"
        :value="params.search"
      />
    </div>
  </div>
  <div class="row">
    <Table
      v-if="announcements.length > 0"
      :headers="headers"
      :items="announcements"
    />
    <div v-else-if="params.search">
      <SearchNotFound />
    </div>
    <div v-else>
      <div
        class="col-md-12 d-flex py-5 flex-column aligin-items-center justify-content-center"
      >
        <img
          class="empty-img py-3"
          src="@/assets/img/announcement-list-clean.svg"
          alt="announcement list clean"
        />
        <p class="text-black text-center fw-normal">
          Você ainda não recebeu comunicados.
        </p>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 d-flex justify-content-end">
      <Pagination
        :numberPage="numberPage"
        routeName="announcements-list"
        :currentPage="currentPage"
      />
    </div>
  </div>
</template>
<script>
import SearchInput from "@/components/Custom/SearchInput.vue";
import Table from "@/components/Custom/Table.vue";
import SearchNotFound from "@/components/SearchNotFound/SearchNotFound.vue";
import Pagination from "@/components/Pagination/Pagination.vue";
import AnnouncementService from "@/services/announcement-service.js";
export default {
  name: "AnnouncementListing",
  components: {
    SearchInput,
    Table,
    SearchNotFound,
    Pagination,
  },
  data() {
    return {
      announcements: [],
      headers: [
        {
          text: "Assunto do comunicado",
          value: "assuntoComunicado",
          tooltip: true,
        },
        {
          text: "Nome do fundo",
          value: "nomeFundo",
          tooltip: true,
        },
        {
          text: "Data do envio",
          value: "dataEnvio",
          type: "date-iso",
        },
        {
          text: "Ações",
          value: "acoes",
          align: "right",
          type: "action",
        },
      ],
      params: {
        limit: 10,
        page: 1,
        search: "",
      },
      numberPage: 0,
      currentPage: 1,
    };
  },
  mounted() {
    if (this.$route.query) {
      this.params.search = this.$route.query.search ?? this.params.search;
      this.params.limit = this.$route.query.limit ?? this.params.limit;
      this.params.page = this.$route.query.page ?? this.params.page;
    }
    this.getAnnouncements();
  },
  methods: {
    async getAnnouncements() {
      const data = await AnnouncementService.getAll(this.params);
      this.numberPage = data.totalPage;
      this.currentPage = data.page;
      let newAnnouncements = [];
      data.results.forEach((item) => {
        newAnnouncements.push({
          idComunicado: item._id,
          assuntoComunicado: item.subject,
          nomeFundo: item.fund.name,
          idFundo: item.fund._id,
          dataEnvio: item.date,
          acoes: [
            {
              color: "#fe7a01",
              title: "Ver comunicado",
              icon: "fa-regular fa-eye",
              onClick: this.showAnnouncement,
            },
          ],
        });
      });
      this.announcements = newAnnouncements;
    },
    searchInputEmit(value) {
      this.params.search = value;
      let query = {
        limit: this.params.limit,
        page: this.params.page,
      };
      if (value) {
        query.limit = 10;
        query.page = 1;
        query.search = value;
      }
      this.$router.push({
        name: "announcements-list",
        query,
      });
    },
    showAnnouncement(item) {
      this.$router.push({
        name: "announcements-detail",
        params: {
          id: item.idComunicado,
          fund: item.idFundo,
        },
      });
    },
  },
  watch: {
    $route(to) {
      if (to.name === "announcements-list") {
        const hasDifferentParams = Object.keys(to.query).some((key) => {
          return to.query[key] !== this.params[key];
        });
        if (hasDifferentParams) {
          this.params.search = to.query.search ?? this.params.search;
          this.params.limit = to.query.limit ?? this.params.limit;
          this.params.page = to.query.page ?? this.params.page;
          this.getAnnouncements();
          return false;
        }
        if (Object.keys(to.query).length === 0) {
          this.params.search = "";
          this.params.limit = 10;
          this.params.page = 1;
        }
        this.getAnnouncements();
      }
    },
  },
};
</script>
