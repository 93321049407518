<template>
  <div class="dc-main">
    <div>
      <CustomSide />
    </div>
    <div v-if="showForm" class="dc-container">
      <div class="dc-body">
        <div class="dc-title">Confirmação de dados</div>
        <div v-if="isPco" class="dc-subtitle">
          Para prosseguir, digite o seu código de cliente.
        </div>
        <div v-else class="dc-subtitle">
          Para prosseguir, digite os 5 primeiros números do seu CPF ou CNPJ.
        </div>
        <div v-if="isPco" class="mt-32">
          <div class="form-control-icon">
            <i class="icon far fa-fw fa-lock-keyhole" style="color: #ff7a00" />
            <input
              ref="inputCodePco"
              v-model="codePco"
              type="password"
              class="form-control"
              placeholder="Insira o código numérico"
              style="padding-right: 3rem"
            />
            <a v-if="hideCodePco" class="icon-pos-right" @click="toggleCodePco">
              <i class="fa-regular fa-eye"></i>
            </a>
            <a v-else class="icon-pos-right" @click="toggleCodePco">
              <i class="fa-regular fa-eye-slash"></i>
            </a>
          </div>
        </div>
        <div v-else class="dc-inputs" ref="inputs">
          <input
            type="tel"
            ref="input1"
            required
            @input="inputNumber"
            @keydown.backspace="pressBackspace"
            maxlength="1"
          />
          <input
            type="tel"
            ref="input2"
            @input="inputNumber"
            @keydown.backspace="pressBackspace"
            maxlength="1"
          />
          <input
            type="tel"
            ref="input3"
            @input="inputNumber"
            @keydown.backspace="pressBackspace"
            maxlength="1"
          />
          <input
            type="tel"
            ref="input4"
            @input="inputNumber"
            @keydown.backspace="pressBackspace"
            maxlength="1"
          />
          <input
            type="tel"
            ref="input5"
            @input="inputNumber"
            @keydown.backspace="pressBackspace"
            @keyup.enter="btnContinue"
            maxlength="1"
          />
        </div>
        <div class="dc-message d-none" ref="message"></div>
        <div class="dc-button">
          <button class="btn btn-primary" @click="btnContinue">
            Confirmar
          </button>
        </div>
      </div>
    </div>
    <div class="modal fade" ref="modalConfirmData" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-dialog-vottar">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Tudo certo!</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="md-vt-img">
              <img style="height: 80px" src="@/assets/img/check-user.svg" />
            </div>
            <div class="md-vt-text">
              <strong>Tudo certo com seus dados!</strong>
              <br />
              <br />
              Pra ver a assembleia e realizar a votação é necessário aceitar os
              termos abaixo.
            </div>
            <div class="md-vt-checkbox">
              <div>
                <input
                  id="acceptanceTerm"
                  type="checkbox"
                  class="form-check-input"
                  ref="checkbox"
                  @change="checkboxChange"
                />
              </div>
              <label for="acceptanceTerm">
                Li e concordo com os
                <router-link
                  :to="{ name: 'termos-e-condicoes-de-uso' }"
                  target="_blank"
                  >Termos de uso</router-link
                >
                e
                <router-link
                  :to="{ name: 'politica-de-privacidade' }"
                  target="_blank"
                  >Política de privacidade</router-link
                >.
              </label>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="seeAssembly"
              disabled
            >
              Ver assembleia
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSide from "@/components/CustomSide/customSide.vue";
import { Modal } from "bootstrap";
import AssemblyService from "@/services/assembly-service";
import { base64decodetoJson } from "../../utils/utils";
export default {
  name: "data-confirmation-layout",
  components: {
    CustomSide,
  },
  data() {
    return {
      input: "",
      checkbox: false,
      token: "",
      showForm: false,
      isPco: false,
      codePco: null,
      hideCodePco: true,
    };
  },
  methods: {
    toggleCodePco() {
      this.hideCodePco = !this.hideCodePco;
      this.$refs.inputCodePco.type = this.hideCodePco ? "password" : "text";
      this.$refs.inputCodePco.focus();
    },
    setValueInInput() {
      this.input =
        this.$refs.input1.value +
        this.$refs.input2.value +
        this.$refs.input3.value +
        this.$refs.input4.value +
        this.$refs.input5.value;
    },
    inputNumber(event) {
      const regex = /^[0-9]*$/;
      if (!regex.test(event.target.value)) {
        event.target.value = "";
        return;
      }
      this.setValueInInput();
      if (
        event.target.value.length === 1 &&
        event.target !== this.$refs.input5
      ) {
        event.target.nextElementSibling.focus();
      }
      if (
        event.target.value.length === 0 &&
        event.target !== this.$refs.input1
      ) {
        event.target.previousElementSibling.focus();
        event.target.previousElementSibling.value = "";
      }
    },
    pressBackspace(event) {
      if (event.target !== this.$refs.input1) {
        event.target.value = "";
        event.target.previousElementSibling.focus();
        event.target.previousElementSibling.value = "";
      }
      this.setValueInInput();
    },
    showMessageError(message) {
      this.$refs.message.classList.remove("d-none");
      this.$refs.message.classList.add("d-block");
      this.$refs.message.innerHTML = message;
      if (!this.isPco) {
        this.$refs.inputs.classList.add("invalid");
      }
    },
    hideMessageError() {
      this.$refs.message.classList.remove("d-block");
      this.$refs.message.classList.add("d-none");
      if (!this.isPco) {
        this.$refs.inputs.classList.remove("invalid");
      }
    },
    async btnContinue() {
      let code = null;
      if (this.isPco) {
        if (this.codePco === null || this.codePco === "") {
          this.showMessageError("Insira o código numérico");
          this.$refs.inputCodePco.focus();
          return;
        }
        this.hideMessageError();
        code = this.codePco;
      } else {
        if (this.input.length < 5) {
          this.showMessageError("Preencha todos os campos");
          const inputs = this.$refs.inputs.childNodes;
          for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].value === "") {
              inputs[i].focus();
              break;
            }
          }
          return;
        }
        this.hideMessageError();
        code = this.input;
      }
      await AssemblyService.validateVotingToken(this.token, code)
        .then((response) => {
          const tokenDecoded = base64decodetoJson(
            response.access_token.split(".")[1]
          );
          response.username = tokenDecoded.username;
          response.loggedOut = true;
          localStorage.setItem("user", JSON.stringify(response));
          this.$refs.checkbox.checked = false;
          this.$refs.modalConfirmData
            .querySelector(".modal-footer button")
            .setAttribute("disabled", true);
          this.modalConfirmData.show();
        })
        .catch((e) => {
          if (e.response.data.messages.includes("CPF or CNPJ is invalid")) {
            this.showMessageError(
              "Dados inválidos. Verifique os números inseridos e tente novamente"
            );
          } else {
            this.showMessageError(
              "Algo deu errado. Tente novamente mais tarde."
            );
          }
        });
    },
    checkboxChange(event) {
      this.checkbox = event.target.checked;
      if (this.checkbox) {
        this.$refs.modalConfirmData
          .querySelector(".modal-footer button")
          .removeAttribute("disabled");
      } else {
        this.$refs.modalConfirmData
          .querySelector(".modal-footer button")
          .setAttribute("disabled", true);
      }
    },
    seeAssembly() {
      this.modalConfirmData.hide();
      this.$router.push({
        name: "logged-out-assembly-vote",
        query: { token: this.token },
      });
    },
    async getAssemblyStatus(token) {
      return await AssemblyService.getStatusAssemblyByVotingToken(token);
    },
  },
  async mounted() {
    localStorage.clear();
    this.token = this.$route.query.token;
    if (!this.token) {
      this.$router.push({ name: "user-login" });
    }
    try {
      const response = await this.getAssemblyStatus(this.token);
      if (response.status === "Em andamento") {
        this.showForm = true;
        this.isPco = response.isPco;
      } else {
        this.$router.push({
          name: "vote-not-available",
        });
        return;
      }
    } catch (error) {
      console.log(error);
      this.isPco = false;
      this.$router.push({
        name: "user-login",
      });
      return;
    }
    setTimeout(() => {
      if (this.isPco) {
        this.$refs.inputCodePco.focus();
      } else {
        this.$refs.input1.focus();
      }
      this.modalConfirmData = new Modal(this.$refs.modalConfirmData);
    }, 100);
  },
};
</script>
<style scoped>
.mt-32 {
  margin-top: 32px;
}
.icon-pos-right {
  right: 0;
  top: 0;
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-pos-right:hover {
  color: #000000;
}
.dc-main {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
}

.dc-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dc-body {
  width: 300px;
}
.dc-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.dc-subtitle {
  margin-top: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.dc-inputs {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
}
.dc-inputs input {
  text-align: center;
  width: 54px;
  height: 56px;
  border: 1px solid #a7a9b6;
  border-radius: 15px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}
.dc-inputs input:focus {
  outline: none;
  border: 1px solid #ff7a00;
}
.dc-inputs.invalid > input {
  background-color: var(--color-form-invalid-background);
  border: 1px solid var(--color-form-invalid-border);
}
.dc-message {
  margin-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #dc342b;
}
.dc-button {
  margin-top: 32px;
  display: flex;
  justify-content: center;
}
.dc-button button {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  border-radius: 15px;
}
.md-vt-img {
  display: flex;
  justify-content: center;
}
.md-vt-text {
  margin-top: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.md-vt-checkbox {
  margin-top: 24px;
  display: flex;
  align-items: flex-start;
}
.md-vt-checkbox > div {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.md-vt-checkbox > div > input {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}
.md-vt-checkbox > div > a {
  color: #ff7a00;
}
.modal-dialog-vottar > .modal-content {
  border-radius: 20px;
}
.modal-dialog-vottar > .modal-content > .modal-body {
  padding: 22px;
  padding-top: 0px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.modal-dialog-vottar > .modal-content > .modal-footer {
  border-top: 1px solid #dee2e6;
}
.modal-dialog-vottar > .modal-content > .modal-footer,
.modal-dialog-vottar > .modal-content > .modal-header {
  padding: 22px;
}
.modal-dialog-vottar > .modal-content > .modal-footer > button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  border-radius: 15px;
  border: none;
}
.modal-dialog-vottar > .modal-content > .modal-footer > button:disabled {
  background-color: #83848f;
}
@media screen and (min-width: 769px) {
  .dc-main {
    flex-direction: row;
  }
  .modal-dialog-vottar {
    width: 350px;
  }
  .modal-dialog-vottar > .modal-content > .modal-header > button {
    display: none;
  }

  .dc-container {
    overflow-y: auto;
  }
}
@media screen and (max-width: 768px) {
  .dc-container {
    align-items: flex-start;
  }
}
</style>
