<template>
  <div class="row">
    <div class="col-12">
      <div v-if="voted" class="assembly-info-vote">
        <div>
          <img src="@/assets/img/icon-circle-check.svg" />
          <div>Voto registrado!</div>
        </div>
      </div>
      <div class="assembly-title fw-600">Informações gerais</div>
    </div>
  </div>
  <div class="row mt-10">
    <div class="col-lg-4 col-md-6 col-sm-12 mt-15">
      <div class="assembly-title fw-500">Nome do fundo:</div>
      <div class="assembly-group">
        <div class="assembly-icon">
          <img src="@/assets/img/icon-databases.svg" />
        </div>
        <div class="assembly-title">{{ assembly.name }}</div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mt-15">
      <div class="assembly-title fw-500">CNPJ do fundo:</div>
      <div class="assembly-group">
        <div class="assembly-icon">
          <img src="@/assets/img/icon-buildings.svg" />
        </div>
        <div class="assembly-title">{{ assembly.cnpj }}</div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mt-15">
      <div class="assembly-title fw-500">Fim da votação:</div>
      <div class="assembly-group">
        <div class="assembly-icon">
          <img src="@/assets/img/icon-calendar.svg" />
        </div>
        <div class="assembly-title">{{ assembly.endVoting }}</div>
      </div>
    </div>
  </div>
  <div class="row mt-40">
    <div class="col-12">
      <div class="assembly-title fw-600">Convocação</div>
    </div>
    <div class="col-12 mt-16">
      <div class="assembly-text-area" v-html="assembly.convocation"></div>
    </div>
  </div>
  <div class="row mt-40">
    <div class="col-12 mb-15">
      <div class="assembly-title fw-600">Ordem do dia</div>
    </div>

    <div
      class="col-12 mt-10"
      ref="ordersOfTheDay"
      v-for="(item, index) in assembly.ordersOfTheDay"
      :key="index"
    >
      <div class="assembly-accordion-main">
        <div class="assembly-accordion-header" @click="toggleAccordion">
          <div class="assembly-accordion-header-title">{{ item.title }}</div>
          <div class="assembly-accordion-header-icon">
            <img src="@/assets/img/chevron-left.svg" />
          </div>
        </div>
        <div class="assembly-accordion-body">
          <div class="assembly-order-content" v-html="item.content"></div>
          <div class="assembly-order-options">
            <div
              class="form-check"
              v-for="(option, idx) in item.options"
              :key="idx"
            >
              <input
                class="form-check-input"
                type="radio"
                :id="item.id + '}-{' + option"
                :name="item.title"
                :value="option"
              />
              <label class="form-check-label" :for="item.id + '}-{' + option">{{
                option
              }}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="assembly-accordion-message">
        É necessário selecionar uma opção de voto para finalizar a votação.
      </div>
    </div>
  </div>
  <div class="row mt-40">
    <div class="col-12">
      <button class="btn btn-primary float-end" @click="registerVote">
        {{ textButton }}
      </button>
    </div>
  </div>
  <div class="modal fade" ref="modalVoteConfirmed" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-vottar">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{ textModalTitle }}</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="md-vt-img">
            <img style="height: 80px" src="@/assets/img/check-slot.svg" />
          </div>
          <div class="md-vt-text">
            <strong>{{ textModalSubtitle }}</strong>
            <br />
            <br />
            Você pode alterar seu voto enquanto a assembleia estiver em
            andamento.
            <br />
            <div v-if="!isPco">
              <br />
              Faça o login para acompanhar suas assembleias e comunicados.
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            :class="isPco ? 'btn btn-primary' : 'btn btn-secondary'"
            @click="modalVoteConfirmed.hide()"
          >
            Fechar
          </button>

          <button
            v-if="!isPco"
            type="button"
            class="btn btn-primary"
            @click="login"
          >
            Fazer login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import AssemblyService from "@/services/assembly-service";
import { generateMessage } from "@/utils/utils";
export default {
  name: "logged-out-assembly-vote",
  data() {
    return {
      isPco: false,
      token: "",
      voted: false,
      assembly: {
        id: "",
        name: "",
        cnpj: "",
        endVoting: "",
        ordersOfTheDay: [],
        convocation: "",
      },
      updateAssembly: false,
      textButton: "Registrar voto",
      textModalTitle: "Voto registrado!",
      textModalSubtitle: "Voto registrado com sucesso!",
    };
  },
  async mounted() {
    this.token = this.$route.query.token;
    if (!this.token) {
      generateMessage("Token inválido", "ERROR", this);
      this.$router.push({ name: "user-login" });
    }
    this.modalVoteConfirmed = new Modal(this.$refs.modalVoteConfirmed);
    await this.getAssembly();
    window.addEventListener("resize", this.updateAccordionHeight);
    this.$refs.modalVoteConfirmed.addEventListener("hidden.bs.modal", () => {
      if (this.updateAssembly) {
        this.updateAssembly = false;
        this.getAssembly();
      }
    });
    await this.getAssemblyStatus();
  },
  methods: {
    login() {
      this.updateAssembly = false;
      this.modalVoteConfirmed.hide();
      this.$router.push({ name: "user-login" });
    },
    async getAssemblyStatus() {
      const token = this.$route.query.token;
      try {
        var response = await AssemblyService.getStatusAssemblyByVotingToken(
          token
        );
        this.isPco = response.isPco;
      } catch (error) {
        this.isPco = false;
        if (error?.status === 401 || error?.message === "token_not_found") {
          generateMessage("Sessão expirada, tente novamente.", "ERROR", this);
        } else {
          generateMessage("Erro ao carregar a assembleia", "ERROR", this);
        }
        this.$router.push({ name: "user-login" });
      }
    },
    async registerVote() {
      const votes = this.$refs.ordersOfTheDay.map((item) => {
        item
          .querySelector(".assembly-accordion-main")
          .classList.remove("required");
        item.querySelector(".assembly-accordion-message").style.display =
          "none";
        if (item.querySelector("input:checked")) {
          const [dayOrderId, option] = item
            .querySelector("input:checked")
            .id.split("}-{");
          return {
            dayOrderId,
            option,
          };
        } else {
          item
            .querySelector(".assembly-accordion-main")
            .classList.add("required");
          item.querySelector(".assembly-accordion-message").style.display =
            "block";
          return null;
        }
      });
      if (votes.some((item) => item === null)) return;
      await AssemblyService.registerVote(this.assembly.id, votes)
        .then(() => {
          this.updateAssembly = true;
          this.modalVoteConfirmed.show();
        })
        .catch((error) => {
          if (error?.status === 401 || error?.message === "token_not_found") {
            generateMessage("Sessão expirada, tente novamente.", "ERROR", this);
            this.$router.push({ name: "user-login" });
          }
          if (
            error?.response?.data?.messages.includes(
              "Assembly is not in progress"
            )
          ) {
            generateMessage(
              "A assembleia não está em andamento.",
              "ERROR",
              this
            );
          } else {
            generateMessage(
              "Ocorreu um erro ao registrar o voto.",
              "ERROR",
              this
            );
          }
        });
    },
    async getAssembly() {
      await AssemblyService.getAssemblyByVotingToken(this.token)
        .then(({ assembly, vote }) => {
          this.assembly.id = assembly._id;
          this.assembly.name = assembly.fund.name;
          this.assembly.cnpj = assembly.fund.cnpj.replace(
            /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
            "$1.$2.$3/$4-$5"
          );
          this.assembly.endVoting = new Date(assembly.votingEndDate)
            .toLocaleString("pt-BR", {
              timeZone: "America/Sao_Paulo",
            })
            .replace(/:\d{2}$/, "")
            .replace(",", "");
          this.assembly.convocation = assembly.convocation;
          const ordersOfTheDay = assembly.dayOrder.map((item) => {
            return {
              title: item.name,
              content: item.description,
              options: item.votingOptions,
              id: item._id,
            };
          });
          this.assembly.ordersOfTheDay = ordersOfTheDay;
          if (vote) {
            this.voted = true;
            this.textButton = "Atualizar voto";
            this.textModalTitle = "Voto atualizado!";
            this.textModalSubtitle = "Voto atualizado com sucesso!";
            setTimeout(() => {
              vote.votes.map((item) => {
                const input = document.getElementById(
                  `${item.dayOrderId}}-{${item.option}`
                );
                input.checked = true;
              });
            }, 1);
          }
        })
        .catch((error) => {
          if (error?.status === 401 || error?.message === "token_not_found") {
            generateMessage("Sessão expirada, tente novamente.", "ERROR", this);
          } else {
            generateMessage("Erro ao carregar a assembleia", "ERROR", this);
          }
          this.$router.push({ name: "user-login" });
        });
    },
    updateAccordionHeight() {
      const assemblyAccordionBody = document.querySelectorAll(
        ".assembly-accordion-body"
      );
      assemblyAccordionBody.forEach((item) => {
        item.style.maxHeight = item.scrollHeight + "px";
      });
    },
    toggleAccordion(event) {
      if (
        event.target
          .closest(".assembly-accordion-main")
          .classList.contains("closed")
      ) {
        event.target
          .closest(".assembly-accordion-main")
          .classList.remove("closed");
      } else {
        const assemblyAccordionBody = event.target
          .closest(".assembly-accordion-main")
          .querySelector(".assembly-accordion-body");
        assemblyAccordionBody.style.maxHeight =
          assemblyAccordionBody.scrollHeight + "px";
        setTimeout(() => {
          event.target
            .closest(".assembly-accordion-main")
            .classList.add("closed");
        }, 1);
      }
    },
  },
};
</script>
<style scoped>
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-40 {
  margin-top: 40px;
}
.mb-15 {
  margin-bottom: 15px;
}
.assembly-info-vote {
  display: flex;
  margin-bottom: 24px;
}
.assembly-info-vote > div {
  display: flex;
  align-items: center;
  background: #e6e7ec;
  border-radius: 15px;
  padding: 8px 16px;
}
.assembly-info-vote > div > img {
  width: 16px;
  height: 16px;
}
.assembly-info-vote > div > div {
  margin-left: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.assembly-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.assembly-group {
  display: flex;
  margin-top: 8px;
}
.assembly-icon {
  margin-right: 8px;
  margin-top: 3px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.assembly-text-area {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  min-height: 60px;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  padding: 16px;
}
/* init accordion css */
.assembly-accordion-main {
  display: flex;
  flex-direction: column;
}
.assembly-accordion-message {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #dc342b;
  margin-top: 9px;
  margin-bottom: 7px;
  display: none;
}
.assembly-accordion-header {
  min-height: 56px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.assembly-accordion-main.required .assembly-accordion-header {
  border: 1px solid #dc342b;
  border-bottom: 1px solid #ebebeb;
}
.assembly-accordion-main.required .assembly-accordion-body {
  border: 1px solid #dc342b;
  border-top: 0px;
}
.assembly-accordion-main.required.closed .assembly-accordion-header {
  border-bottom: 1px solid #dc342b;
}
.assembly-accordion-header-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 10px 16px;
  flex: 1;
}
.assembly-accordion-header-icon {
  margin-right: 19px;
}
.assembly-accordion-header-icon > img {
  rotate: 90deg;
  transition: 0.3s;
}
.assembly-accordion-body {
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  border-top: 0px;
  opacity: 1;
  transition: 0.3s;
}
.assembly-accordion-main.closed .assembly-accordion-header-icon > img {
  rotate: -90deg;
}
.assembly-accordion-main.closed .assembly-accordion-header {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.assembly-accordion-main.closed .assembly-accordion-body {
  max-height: 0px !important;
  opacity: 0;
}
/* end accordion css */
.assembly-order-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin: 32px 16px 0px;
}
.assembly-order-options {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 16px 20px;
}
.assembly-order-options > div {
  margin-right: 30px;
  margin-top: 0px;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.md-vt-img {
  display: flex;
  justify-content: center;
}
.md-vt-text {
  margin-top: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.modal-dialog-vottar > .modal-content {
  border-radius: 20px;
}
.modal-dialog-vottar > .modal-content > .modal-body {
  padding: 22px;
  padding-top: 0px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.modal-dialog-vottar > .modal-content > .modal-footer {
  border-top: 1px solid #dee2e6;
}
.modal-dialog-vottar > .modal-content > .modal-footer,
.modal-dialog-vottar > .modal-content > .modal-header {
  padding: 22px;
}
.modal-dialog-vottar > .modal-content > .modal-footer > button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  border-radius: 15px;
  border: none;
}
@media screen and (max-width: 768px) {
  .assembly-order-options {
    margin-bottom: 0px;
  }
  .assembly-order-options > div {
    padding-bottom: 20px;
  }
}
@media screen and (min-width: 769px) {
  .modal-dialog-vottar {
    width: 350px;
  }
  .modal-dialog-vottar > .modal-content > .modal-header > button {
    display: none;
  }
}
</style>
