<template>
    <h1></h1>
    
</template>
<script>
 export default {
    name:'dashboard-home',
    components:{
        
    }
  
 }
</script>