<template>
  <div class="acd-main">
    <div>
      <CustomSide />
    </div>
    <div class="acd-container">
      <div class="acd-body">
        <div class="acd-body-img">
          <img
            src="@/assets/img/warning-alert-access-denied.svg"
            alt="access denied"
          />
        </div>
        <div class="acd-body-title">Acesso não autorizado!</div>
        <div class="acd-body-subtitle">
          Você não tem permissão para acessar esta página.
        </div>
        <div class="acd-body-button">
          <button
            class="btn btn-primary"
            @click="this.$router.push({ name: 'home' })"
          >
            Voltar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSide from "@/components/CustomSide/customSide.vue";
export default {
  name: "page-not-found",
  components: {
    CustomSide,
  },
};
</script>
<style scoped>
.acd-main {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
}
.acd-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acd-body {
  width: 300px;
}
.acd-body-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.acd-body-title {
  margin-top: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  text-align: center;
}
.acd-body-subtitle {
  margin-top: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
.acd-body-button {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.acd-body-button button {
  width: 100%;
  height: 56px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border-radius: 15px;
}
@media screen and (min-width: 769px) {
  .acd-main {
    flex-direction: row;
  }
  .acd-container {
    overflow-y: auto;
  }
}
@media screen and (max-width: 768px) {
  .acd-container {
    align-items: flex-start;
  }
}
</style>
