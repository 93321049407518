<template>
  <div class="filter-table-main dropdown">
    <div
      class="filter-table-button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      data-bs-auto-close="outside"
    >
      <span class="filter-table-button-text">Filtro</span>
      <i class="fa-regular fa-filter"></i>
      <span
        class="filter-table-button-badge"
        v-bind:class="{ visible: filtered }"
      ></span>
    </div>
    <div
      ref="dropdown"
      class="filter-table-dropdown dropdown-menu dropdown-menu-end"
    >
      <div class="filter-table-dropdown-header">
        <span class="filter-table-dropdown-title">Filtro</span>
        <span class="filter-table-dropdown-clear" @click="clearFilter"
          >Limpar filtros</span
        >
      </div>
      <div class="filter-table-dropdown-body">
        <Accordion
          customStyle="custom-filter-table"
          v-for="filter in arrFilters"
          :ref="filter._id"
          :key="filter._id"
          :title="filter.title"
          @toggle="toggleAccordion(filter._id)"
        >
          <div class="filter-table-dropdown-item">
            <div
              class="filter-table-dropdown-item-options"
              v-for="option in filter.options"
              :key="option._id"
            >
              <div v-if="filter.type === 'radio'">
                <input
                  class="form-check-input"
                  type="radio"
                  :name="filter._id"
                  :id="option._id"
                  :checked="option.selected"
                />
                <label class="form-check-label" :for="option._id">
                  {{ option.title }}
                </label>
              </div>
              <div v-else-if="filter.type === 'checkbox'">
                <input
                  class="form-check-input"
                  type="checkbox"
                  :name="filter._id"
                  :id="option._id"
                  :checked="option.selected"
                />
                <label class="form-check-label" :for="option._id">
                  {{ option.title }}
                </label>
              </div>
              <div v-else-if="filter.type === 'date'">
                <span>{{ option.title }}</span>
                <div class="filter-table-dropdown-group-input">
                  <div @click="openDatePicker(option._id)">
                    <i class="fa-regular fa-calendar" />
                  </div>
                  <input
                    :ref="option._id"
                    type="date"
                    placeholder="DD/MM/AAAA"
                  />
                </div>
              </div>
            </div>
          </div>
        </Accordion>
      </div>
      <div class="filter-table-dropdown-footer">
        <button class="btn btn-primary" @click="applyFilter">Aplicar</button>
      </div>
    </div>
  </div>
</template>
<script>
import Accordion from "@/components/Custom/Accordion.vue";
export default {
  name: "FilterTable",
  components: {
    Accordion,
  },
  props: {
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filtered: false,
      arrFilters: [],
    };
  },
  mounted() {
    this.arrFilters = JSON.parse(JSON.stringify(this.filters));
    this.prepareFilters();
  },
  methods: {
    generateKey(i, type = "div") {
      return `${i}-${type}-${Math.random().toString(36).slice(2, 11)}`;
    },
    prepareFilters() {
      this.arrFilters.forEach((filter, i) => {
        filter._id = this.generateKey(i, "filter");
        filter.options.forEach((option, j) => {
          option._id = this.generateKey(j, "option");
          option.selected = option.selected ?? false;
        });
      });
    },
    applyFilter() {
      const selectedFilters = [];
      this.arrFilters.forEach((filter) => {
        filter.options.forEach((option) => {
          switch (filter.type) {
            case "radio":
            case "checkbox":
              if (document.getElementById(option._id).checked && option.value) {
                selectedFilters.push({ [filter.value]: option.value });
              }
              break;
            case "date":
              if (this.$refs[option._id][0].value) {
                selectedFilters.push({
                  [option.value]: this.$refs[option._id][0].value,
                });
              }
              break;
            default:
              break;
          }
        });
      });

      const filteredResults = selectedFilters.reduce((acc, filter) => {
        const key = Object.keys(filter)[0];
        if (!acc[key]) {
          acc[key] = filter[key];
        } else if (Array.isArray(acc[key])) {
          acc[key].push(filter[key]);
        } else {
          acc[key] = [acc[key], filter[key]];
        }
        return acc;
      }, {});
      if (Object.keys(filteredResults).length > 0) {
        this.filtered = true;
      } else {
        this.filtered = false;
      }
      this.$emit("applyFilter", filteredResults);
      this.$refs.dropdown.classList.remove("show");
    },
    clearFilter() {
      this.arrFilters = JSON.parse(JSON.stringify(this.filters));
      this.prepareFilters();
      this.filtered = false;
      this.$emit("applyFilter", {});
      this.$refs.dropdown.classList.remove("show");
    },
    openDatePicker(ref) {
      this.$refs[ref][0].showPicker();
    },
    toggleAccordion(id) {
      this.arrFilters.map((filter) => {
        if (filter._id !== id) {
          this.$refs[filter._id][0].closeAccordion();
        }
      });
    },
  },
  watch: {
    filters: {
      handler: function (val) {
        this.arrFilters = val;
        this.prepareFilters();
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
.filter-table-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 38px;
  background: #ffffff;
  border: 1px solid #e6e7ec;
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
}
.filter-table-button:hover {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}
.filter-table-button-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-right: 8px;
  user-select: none;
}
.filter-table-button-badge {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 8px;
  height: 8px;
  background: var(--color-primary);
  border-radius: 50%;
  display: none;
}
.filter-table-button-badge.visible {
  display: block;
}
.filter-table-dropdown {
  width: 264px;
  top: 8px !important;
  padding: 16px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  border-radius: 15px;
}
.filter-table-dropdown-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}
.filter-table-dropdown-title {
  margin-left: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  user-select: none;
}
.filter-table-dropdown-clear {
  margin-right: 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: var(--color-primary);
  cursor: pointer;
}
.filter-table-dropdown-item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.filter-table-dropdown-item-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.filter-table-dropdown-item-options {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.filter-table-dropdown-item-options .form-check-input {
  margin-right: 8px;
}
.filter-table-dropdown-item-options
  .form-check-input:checked
  + .form-check-label {
  font-weight: 600;
}
.filter-table-dropdown-group-input {
  display: flex;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid #a7a9b6;
  align-items: center;
  margin-top: 8px;
  height: 53px;
}
.filter-table-dropdown-group-input input {
  flex: 1;
  border: none;
  margin-left: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 21px;
  color: #595b69;
  background-color: #ffffff;
  text-transform: uppercase;
}
.filter-table-dropdown-group-input svg {
  color: var(--color-primary);
  cursor: pointer;
}
.filter-table-dropdown-group-input input:focus {
  outline: none;
}
.filter-table-dropdown-group-input input::-webkit-inner-spin-button,
.filter-table-dropdown-group-input input::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
.filter-table-dropdown-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
}
.filter-table-dropdown-footer .btn {
  width: auto;
  height: auto;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  border-radius: 900px;
  padding: 6px 12px;
}
</style>
