import api from "./api";
import config from "../../config";

const BASE_URL = config.app.baseUrl;
const MICRO_SERVICE = BASE_URL + "/fundos-cotistas";
class ShareholderService {
  async updateShareholderFirstLogin(params) {
    const headers = { dontUseBearerToken: true };
    return api
      .put(`${MICRO_SERVICE}/api/shareholder/first-login/update`, params, {
        headers,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async validateShareholderFirstLogin(params) {
    const headers = { dontUseBearerToken: true };
    return api
      .post(`${MICRO_SERVICE}/api/shareholder/first-login/validate`, params, {
        headers,
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async firstLogin(params) {
    const headers = { dontUseBearerToken: true };
    return api
      .post(`${MICRO_SERVICE}/api/shareholder/first-login`, params, { headers })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default new ShareholderService();
