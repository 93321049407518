<template>
  <div class="auth-main">
    <div>
      <CustomSide />
    </div>
    <div class="auth-container">
      <router-view v-slot="{ Component }">
        <transition name="fade">
          <div class="auth-component">
            <component :is="Component" />
          </div>
        </transition>
      </router-view>

      <TermosPolitica />
    </div>
  </div>
</template>
<script>
import CustomSide from "@/components/CustomSide/customSide.vue";
import TermosPolitica from "@/components/TermosPolitica/TermosPolitica.vue";
export default {
  name: "auth-layout",
  components: {
    CustomSide,
    TermosPolitica,
  },
};
</script>
<style scoped>
.auth-main {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
}

.auth-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #ffffff;
}

.auth-component {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (min-width: 769px) {
  .auth-main {
    flex-direction: row;
  }

  .auth-container {
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .auth-component {
    align-items: flex-start;
  }
}
</style>
