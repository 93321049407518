<template>
  <div class="recover-password-main">
    <div class="recover-password-title">Esqueci a senha</div>
    <div class="recover-password-subtitle">
      Informe o CPF ou CNPJ associado à sua conta para receber o e-mail com o
      link de alteração de senha.
    </div>
    <div class="recover-password-div-user">
      <div class="recover-password-div-user-label">CPF ou CNPJ*</div>
      <div class="form-control-icon">
        <i class="icon far fa-fw fa-address-card" style="color: #ff7a00" />
        <input
          ref="inputUser"
          @input="setInputUser"
          maxlength="18"
          type="tel"
          class="form-control"
          placeholder="Insira seu CPF ou CNPJ"
        />
      </div>
      <div class="msg-invalid"></div>
    </div>
    <div class="recover-password-div-button">
      <button @click="sendEmail" class="btn btn-primary">Enviar e-mail</button>
    </div>
    <div class="recover-password-div-login">
      <div>Lembrou a senha?&nbsp;</div>
      <router-link to="/auth/login">Clique aqui</router-link>
    </div>
  </div>
  <div class="modal fade" ref="modalSuccessSendEmail" tabindex="-1">
    <div class="modal-dialog modal-dialog-centered modal-dialog-vottar">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">E-mail enviado!</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="md-vt-img">
            <img
              style="height: 117px"
              src="@/assets/img/password-notification.svg"
            />
          </div>
          <div class="md-vt-text">
            <strong
              >Enviamos um e-mail para você cadastrar sua nova senha.</strong
            >
            <br />
            <br />
            Você recebeu o e-mail?
          </div>
        </div>
        <div class="modal-footer">
          <button @click="resendEmail" type="button" class="btn btn-secondary">
            Reenviar e-mail
          </button>
          <button
            @click="modalSuccessSendEmail.hide()"
            type="button"
            class="btn btn-primary"
          >
            Sim
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Modal } from "bootstrap";
import authService from "@/services/auth-service";
export default {
  name: "page-recover-password",
  data() {
    return {
      username: "",
    };
  },
  mounted() {
    this.$refs.inputUser.focus();
    this.modalSuccessSendEmail = new Modal(this.$refs.modalSuccessSendEmail);
  },
  methods: {
    setInputUser() {
      const value = this.$refs.inputUser.value.replace(/\D/g, "");
      this.username = value;
      if (value.length <= 11) {
        this.$refs.inputUser.value = value
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1");
      } else {
        this.$refs.inputUser.value = value
          .replace(/^(\d{2})(\d)/, "$1.$2")
          .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
          .replace(/\.(\d{3})(\d)/, ".$1/$2")
          .replace(/(\d{4})(\d)/, "$1-$2");
      }
    },
    hideAllInvalidMessages() {
      let invalidMessages = document.querySelectorAll(".invalid");
      invalidMessages.forEach((invalidMessage) => {
        invalidMessage.classList.remove("invalid");
      });
      invalidMessages = document.querySelectorAll(".msg-invalid");
      invalidMessages.forEach((invalidMessage) => {
        invalidMessage.classList.remove("d-block");
      });
    },
    showInvalidMessage(ref, message) {
      this.$refs[ref].parentElement.classList.add("invalid");
      this.$refs[ref].parentElement.nextElementSibling.classList.add("d-block");
      this.$refs[ref].parentElement.nextElementSibling.innerHTML = message;
      this.$refs[ref].focus();
    },
    sendEmail() {
      this.hideAllInvalidMessages();
      if (
        this.username.length === 0 ||
        (this.username.length !== 11 && this.username.length !== 14)
      ) {
        this.showInvalidMessage("inputUser", "Insira um CPF ou CNPJ válido");
        return;
      }
      authService.sendRecoveryPassword(this.username, this).then((response) => {
        if (response.status === 200) {
          this.modalSuccessSendEmail.show();
        }
      });
    },
    resendEmail() {
      this.modalSuccessSendEmail.hide();
      this.sendEmail();
    },
  },
};
</script>
<style scoped>
.recover-password-main {
  width: 300px;
}
.recover-password-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.recover-password-subtitle {
  margin-top: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.recover-password-div-user {
  margin-top: 32px;
}
.recover-password-div-user-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}
.recover-password-div-button > button {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-top: 32px;
  border-radius: 15px;
}

.recover-password-div-login {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.recover-password-div-login > a {
  color: #ff7a00;
}
.form-control:hover,
.form-select:hover {
  border-color: #000000;
}
.msg-invalid {
  display: none;
  margin-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #dc342b;
}
.md-vt-img {
  display: flex;
  justify-content: center;
}
.md-vt-text {
  margin-top: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.modal-dialog-vottar > .modal-content {
  border-radius: 20px;
}
.modal-dialog-vottar > .modal-content > .modal-body {
  padding: 22px;
  padding-top: 0px;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.modal-dialog-vottar > .modal-content > .modal-footer {
  border-top: 1px solid #dee2e6;
}
.modal-dialog-vottar > .modal-content > .modal-footer,
.modal-dialog-vottar > .modal-content > .modal-header {
  padding: 22px;
}
.modal-dialog-vottar > .modal-content > .modal-footer > button {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  border-radius: 15px;
  border: none;
}
@media screen and (min-width: 769px) {
  .modal-dialog-vottar {
    width: 350px;
  }
  .modal-dialog-vottar > .modal-content > .modal-header > button {
    display: none;
  }
  .modal-dialog-vottar > .modal-content > .modal-footer > .btn-secondary {
    margin-right: 24px;
  }
}
</style>
