<template>

  <router-view></router-view>
  <LoadingVue v-if="isLoading.isLoading" />
</template>

<script>
import LoadingVue from './components/CustomLoading/Loading.vue';
import { useLoadingOnStore } from './store/loadingOn';
import { Tooltip } from 'bootstrap';

export default {
  name: 'App',
  components: {
    LoadingVue
  },
  setup() {
    const isLoading = useLoadingOnStore(); // UNDEFINED
    return { isLoading };
  },
  data() {
    return {
    }
  },
  mounted() {
    this.importScriptsHead(),
      this.importScriptsBody(),
      this.initTooltip()
  },

  methods: {
    initTooltip() {
      new Tooltip(document.body, {
        selector: "[data-bs-toggle='tooltip']",
        trigger: 'hover'
      });
    },
    importScriptsHead() {
      let scriptsHead = [];
      scriptsHead.push('https://cdn.soluti.com.br/libs/fontawesome/v6/js/all.min.js');
      scriptsHead.forEach((value) => {
        let script = document.createElement('script');
        script.setAttribute('src', value);
        document.head.appendChild(script);
      });
    },
    importScriptsBody() {

      let scriptsBody = [];
      scriptsBody.push('https://cdn.soluti.com.br/libs/bootstrap/v5/js/bootstrap.bundle.min.js');
      scriptsBody.forEach((value) => {
        let script = document.createElement('script');
        script.setAttribute('src', value);
        script.setAttribute('defer', 'defer');
        document.body.appendChild(script);
      });
    }
  }
}
</script>

<style lang="scss">
@import "./assets/styles/scss/config.scss";
</style>