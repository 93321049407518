<template>
  <div class="row">
    <div class="col-12">
      <div class="announcement-detail-title fw-600">Informações do fundo</div>
    </div>
  </div>
  <div class="row mt-10">
    <div class="col-lg-4 col-md-6 col-sm-12 mt-15">
      <div class="announcement-detail-title fw-500">Nome do fundo:</div>
      <div class="announcement-detail-group">
        <div class="announcement-detail-icon">
          <img src="@/assets/img/icon-databases.svg" />
        </div>
        <div class="announcement-detail-title">
          {{ announcement.fund.name }}
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mt-15">
      <div class="announcement-detail-title fw-500">CNPJ do fundo:</div>
      <div class="announcement-detail-group">
        <div class="announcement-detail-icon">
          <img src="@/assets/img/icon-buildings.svg" />
        </div>
        <div class="announcement-detail-title">
          {{ announcement.fund.cnpj }}
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-40">
    <div class="col-12">
      <div class="announcement-detail-title fw-600">Comunicado</div>
    </div>
  </div>
  <div class="row mt-10">
    <div class="col-lg-4 col-md-6 col-sm-12 mt-15">
      <div class="announcement-detail-title fw-500">Assunto do comunicado:</div>
      <div class="announcement-detail-group">
        <div class="announcement-detail-icon">
          <img src="@/assets/img/icon-envelope.svg" />
        </div>
        <div
          class="announcement-detail-title ellipsis"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          :title="announcement.subject"
        >
          {{ announcement.subject }}
        </div>
      </div>
    </div>
    <div class="col-lg-4 col-md-6 col-sm-12 mt-15">
      <div class="announcement-detail-title fw-500">Data do envio:</div>
      <div class="announcement-detail-group">
        <div class="announcement-detail-icon">
          <img src="@/assets/img/icon-calendar.svg" />
        </div>
        <div class="announcement-detail-title">{{ announcement.sendDate }}</div>
      </div>
    </div>
  </div>
  <div class="row mt-24">
    <div class="col-12">
      <div class="announcement-detail-title fw-500">Texto do comunicado:</div>
    </div>
    <div class="col-12 mt-16">
      <div
        class="announcement-detail-text-area"
        v-html="announcement.text"
      ></div>
    </div>
  </div>
</template>
<script>
import AnnouncementService from "@/services/announcement-service.js";
export default {
  name: "AnnouncementDetail",
  data() {
    return {
      id: this.$route.params.id,
      idFund: this.$route.params.fund,
      announcement: {
        fund: {
          name: "",
          cnpj: "",
        },
        subject: "",
        sendDate: "",
        text: "",
      },
    };
  },
  created() {
    this.getAnnouncement();
  },
  methods: {
    async getAnnouncement() {
      try {
        const data = await AnnouncementService.getById(this.id, this.idFund);
        this.announcement.fund.name = data.fund.name;
        this.announcement.fund.cnpj = data.fund.cnpj.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        );
        this.announcement.subject = data.subject;
        this.announcement.sendDate = new Date(data.date)
          .toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo",
          })
          .replace(/:\d{2}$/, "")
          .replace(",", "");
        this.announcement.text = data.content;
      } catch (error) {
        this.$toast.error("Falha ao buscar comunicado", {
          position: "top-right",
        });
        this.$router.push({ name: "announcements-list" });
      }
    },
  },
};
</script>
<style scoped>
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-40 {
  margin-top: 40px;
}
.announcement-detail-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.announcement-detail-group {
  display: flex;
  margin-top: 8px;
}
.announcement-detail-icon {
  margin-right: 8px;
  margin-top: 3px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.announcement-detail-text-area {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  min-height: 60px;
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 20px;
  padding: 16px;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
</style>
