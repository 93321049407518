<template>
  <div class="custom-side-main">
    <div class="custom-side-content">
      <div class="custom-side-img-right"></div>
      <div class="custom-side-img-center">
        <div class="custom-side-logo"></div>
        <div class="custom-side-title">Assembleia Virtual</div>
      </div>
      <div class="custom-side-img-left"></div>
    </div>
    <div class="custom-side-bottom-mobile"></div>
  </div>
</template>

<script>
import config from '../../../config.js';

export default {
  name: "custom-side",
  data() {
    return {
      logoClient: `url("${config.app.logo}")`,
    };
  },
};
</script>

<style scoped>
.custom-side-main {
  background-color: #ebebeb;
  height: 100vh;
  width: 100%;
  min-width: 527px;
  display: flex;
  flex-direction: column;
}
.custom-side-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.custom-side-bottom-mobile {
  display: none;
  width: 100%;
  background-color: #ffffff;
  height: 50px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  box-shadow: 0px -12px 16px rgb(0 0 0 / 3%);
}

.custom-side-img-right {
  background-image: url("../../assets/img/arrow-before-light.svg");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  height: 190px;
  width: 90%;
  margin-top: 24px;
}
.custom-side-img-left {
  background-image: url("../../assets/img/arrow-after-light.svg");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  height: 225px;
  width: 90%;
  margin-bottom: 24px;
}
.custom-side-logo {
  background-image: v-bind(logoClient);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 40px;
  width: 90%;
}

.custom-side-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  margin-top: 15px;
}

.custom-side-img-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 24px;
}

@media screen and (max-width: 1000px) {
  .custom-side-main {
    min-width: 400px;
  }
}
@media screen and (max-width: 768px) {
  .custom-side-main {
    min-width: unset;
    height: 200px;
  }
  .custom-side-bottom-mobile {
    display: block;
  }

  .custom-side-img-left,
  .custom-side-img-right {
    position: absolute;
  }
  .custom-side-content {
    justify-content: center;
  }
  .custom-side-img-right {
    width: 143.75px;
    height: 140.77px;
    margin: 0px;
    right: -57px;
    top: -55px;
  }
  .custom-side-img-left {
    width: 144.71px;
    height: 166.36px;
    margin: 0px;
    bottom: -67px;
    left: -13px;
  }
  .custom-side-logo {
    height: 100%;
    width: 113px;
    margin: 0px;
  }
  .custom-side-title {
    display: none;
  }
  .custom-side-img-center {
    height: 100%;
    width: 100%;
    margin: 0px;
  }
}
@media screen and (min-width: 500px) {
  .custom-side-img-right {
    right: 40px;
  }
  .custom-side-img-left {
    left: 40px;
  }
}
</style>
