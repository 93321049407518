import api from "./api";
import config from "../../config";
import "../middleware/axios";
import {
  base64decodetoJson,
  checkExpiration,
  generateMessage,
  handleErrorMessage,
} from "../utils/utils";
import { createToaster } from "@meforma/vue-toaster";
const BASE_URL = config.app.baseUrl;
const MIC_SERVICE_AUTH = "/auth";
const CALLBACK_URL_RECOVERY_PASSWORD =
  config.app.callbackOperatorRecoveryPassword;
class AuthService {
  login(user) {
    const headers = { dontUseBearerToken: true };
    return api
      .post(
        `${BASE_URL + MIC_SERVICE_AUTH}/api/auth`,
        {
          username: user.username,
          password: user.password,
        },
        { headers }
      )
      .then((response) => {
        if (response.data.access_token) {
          const tokenDecoded = base64decodetoJson(
            response.data.access_token.split(".")[1]
          );
          response.data.username = tokenDecoded.username;
          response.data.loggedOut = false;
          localStorage.setItem("user", JSON.stringify(response.data));
          return response.data;
        }
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
  logout() {
    return api
      .post(`${BASE_URL + MIC_SERVICE_AUTH}/api/revoke`)
      .then(() => {
        localStorage.removeItem("user");
        return true;
      })
      .catch((error) => {
        throw error;
      });
  }
  async isAuthenticator() {
    const token = JSON.parse(localStorage.getItem("user"));
    if (token) {
      if (token.loggedOut) {
        return false;
      }
      if (token.access_token) {
        var token_part = token.access_token.split(".")[1];
        var exp = base64decodetoJson(token_part).exp;
        if (!checkExpiration(exp)) {
          return await this.refreshToken(true);
        }
        return true;
      }
    }
    localStorage.removeItem("user");
    return false;
  }
  sendRecoveryPassword(username, vue) {
    const headers = { dontUseBearerToken: true };
    return api
      .post(
        `${BASE_URL + MIC_SERVICE_AUTH}/api/user/send-recover-password`,
        {
          username: username,
          callbackUrl: CALLBACK_URL_RECOVERY_PASSWORD,
        },
        { headers }
      )
      .then((response) => {
        if (response.status == 200) {
          generateMessage("E-mail enviado com sucesso", "SUCCESS", vue);
          return response;
        }
      })
      .catch((error) => {
        generateMessage(handleErrorMessage(error), "ERROR", vue);
        return error;
      });
  }
  registerPassword(data, vue) {
    const headers = {
      dontUseBearerToken: true,
      Authorization: `Bearer ${data.token}`,
    };
    return api
      .post(
        `${BASE_URL + MIC_SERVICE_AUTH}/api/user/create-password`,
        {
          password: data.password,
          passwordConfirmation: data.passwordConfirmation,
        },
        {
          headers,
        }
      )
      .then((response) => {
        generateMessage("Senha cadastrada com sucesso!", "SUCCESS", vue);
        vue.$router.push({ name: "user-login" });
        return response;
      })
      .catch((error) => {
        generateMessage(handleErrorMessage(error), "ERROR", vue);
        return error;
      });
  }
  async getRoles() {
    try {
      const response = await api.get(`${BASE_URL + MIC_SERVICE_AUTH}/api/role`);
      return response.data;
    } catch (error) {
      return error;
    }
  }
  getRolesUser() {
    const { resources } = JSON.parse(localStorage.getItem("user")) || {
      resources: [],
    };
    return resources;
  }
  getTypeUser() {
    var roles = JSON.parse(this.getRolesUser());

    var role = "";
    const roleUser = roles.find((element) => {
      var tmp = element.split(":");
      return tmp[0] === "operator" && tmp[1] === "is";
    });

    if (roleUser) {
      const parts = roleUser.split(":");
      role = parts[2];
    }
    return role;
  }
  getUserNameCurrentUser() {
    const { username } = JSON.parse(localStorage.getItem("user")) || {
      username: "",
    };
    return username;
  }
  async refreshToken(silent = false) {
    try {
      const { refresh_token, loggedOut, username } = JSON.parse(
        localStorage.getItem("user")
      );
      const headers = { dontUseBearerToken: true };
      const response = await api.post(
        `${BASE_URL + MIC_SERVICE_AUTH}/api/refresh`,
        {
          refreshToken: refresh_token,
        },
        { headers }
      );
      if (response.data.access_token) {
        response.data.username = username;
        response.data.loggedOut = loggedOut;
        localStorage.setItem("user", JSON.stringify(response.data));
        if (!silent) {
          const toaster = createToaster({
            position: "top-right",
            type: "info",
          });
          toaster.show("Sessão renovada, tente novamente.");
        }
        return true;
      } else {
        localStorage.removeItem("user");
        window.location.href = "/";
      }
    } catch (error) {
      localStorage.removeItem("user");
      window.location.href = "/";
    }
  }
}

export default new AuthService();
