import axios from "axios";
import config from "../../config.js";

class VersionService {
  async getVersion() {
    let baseUrl = config.app.baseUrl;
    let frontEndVersion = config.version;

    let endpoints = [
      `${baseUrl}/auth/api/version`,
      `${baseUrl}/assembleia/api/version`,
      `${baseUrl}/fundos-cotistas/api/version`,
      `${baseUrl}/inter-importacao/api/version`,
      `${baseUrl}/notification-service/api/version`,
      `${baseUrl}/operador/api/version`,
      `${baseUrl}/template-service/api/version`,
    ];

    try {
      let versionsBack = [];
      await axios
        .all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then((versions) => {
          versions.map((version) => {
            versionsBack.push(version.data);
          });
        });

      return {
        frontEnd: frontEndVersion,
        backEnd: versionsBack,
      };
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }

  async getHealthCheck() {
    let baseUrl = config.app.baseUrl;

    let endpoints = [
      `${baseUrl}/auth/api/health-check`,
      `${baseUrl}/assembleia/api/health-check`,
      `${baseUrl}/fundos-cotistas/api/health-check`,
      `${baseUrl}/inter-importacao/api/health-check`,
      `${baseUrl}/notification-service/api/health-check`,
      `${baseUrl}/operador/api/health-check`,
      `${baseUrl}/template-service/api/health-check`,
    ];

    try {
      let versionsBack = [];
      await axios
        .all(endpoints.map((endpoint) => axios.get(endpoint)))
        .then((versions) => {
          versions.map((version) => {
            versionsBack.push(version.data);
          });
        });

      return {
        backEnd: [
          {
            service: "Auth-service",
            status: versionsBack[0],
          },
          {
            service: "Assembly-service",
            status: versionsBack[1],
          },
          {
            service: "Funds-Shareholders-service",
            status: versionsBack[2],
          },
          {
            service: "Import-service",
            status: versionsBack[3],
          },
          {
            service: "Notification-service",
            status: versionsBack[4],
          },
          {
            service: "Operator-service",
            status: versionsBack[5],
          },
          {
            service: "Template-service",
            status: versionsBack[6],
          },
        ],
      };
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  }
}

export default new VersionService();
