<template>
  <nav aria-label="">
    <ul v-if="numberPage > 1" class="pagination">
      <li v-if="currentPage != 1" class="page-item">
        <a class="arrow page-link" @click="backPage" aria-label="Previous">
          <i class="icon fa-regular fa-arrow-left"></i>
        </a>
      </li>
      <li v-for="(value, key) in pages" class="page-item" :key="key">
        <a
          v-on:click="changePage(value)"
          :class="{
            'page-link': true,
            active: value === currentPage,
            'no-page': value === '...',
          }"
          >{{ value }}</a
        >
      </li>

      <li v-if="currentPage != numberPage" class="page-item">
        <a class="arrow page-link" @click="nextPage" aria-label="Next">
          <i class="icon fa-regular fa-arrow-right"></i>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "custom-pagination",
  data() {
    return {
      query: this.$route.query,
    };
  },
  props: {
    numberPage: Number,
    routeName: String,
    currentPage: Number,
  },
  methods: {
    changePage(page) {
      if (page === "...") {
        return;
      }
      this.$router.push({
        name: this.routeName,
        query: { ...this.query, page: page },
      });
      this.$emit("update:currentPage", page);
    },
    nextPage() {
      this.changePage(this.currentPage + 1);
    },
    backPage() {
      this.changePage(this.currentPage - 1);
    },
  },
  watch: {
    $route() {
      this.query = this.$route.query;
    },
  },
  mounted() {},
  // beforeRouteUpdate(to, from, next) {

  //     next();
  // },
  computed: {
    pages() {
      let pages = [];
      if (this.currentPage > 2) {
        pages.push(1);
      }
      if (this.currentPage > 3) {
        pages.push("...");
      }
      if (this.currentPage > 1) {
        pages.push(this.currentPage - 1);
      }
      pages.push(this.currentPage);
      if (this.currentPage < this.numberPage) {
        pages.push(this.currentPage + 1);
      }
      if (this.currentPage < this.numberPage - 2) {
        pages.push("...");
      }
      if (this.currentPage < this.numberPage - 1) {
        pages.push(this.numberPage);
      }
      return pages;
    },
  },
  // methods: {
  //     changePage(page) {
  //         if (page === '...') {
  //             return
  //         }
  //         this.$emit('update:currentPage', page)
  //     }
};
</script>

<style scoped>
.pagination {
  gap: 0.5rem;
}

.arrow {
  background: transparent;
  cursor: pointer;
  border: none !important;
  /* border-radius: ; */
}
.arrow:hover {
  background: #e6e7ec !important;
}
.active {
  background: var(--color-primary) !important;
  color: var(--bs-link-color) !important;
  border: 1px solid transparent !important;
  z-index: 0;
}

.page-link {
  cursor: pointer;
  background: #ffffff;
  /* Default Gray */
  height: 30px;
  width: 30px;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #a7a9b6;
  border-radius: 900px;
  transition: 0.3s;
}
.page-link:hover {
  background: var(--color-primary);
  color: var(--bs-link-color);
  border: none;
}

.no-page {
  background: transparent;
  border: none !important;
  cursor: default;
}
.no-page:hover {
  background: transparent;
}
</style>
