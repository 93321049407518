const messages = require("../../translations/translations.json");

const translate = (text) => {
  const preparedText = toSlug(text);
  const lang = localStorage.getItem("lang") || "pt-br";
  return messages[lang][preparedText] || text;
};

const toSlug = (text) => {
  return text
    .trim()
    .toLowerCase()
    .replace(/ /g, "_")
    .replace(/[^\w-]+/g, "")
    .replace(/_+/g, "_");
};

export default translate;
