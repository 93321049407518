<template>
  <div class="cr-main">
    <p class="fw-600 my-4 title">Complete seu cadastro</p>
    <div class="mt-32">
      <label class="text-form mb-2 fw-400"> Nome completo* </label>
      <div
        class="form-control-icon"
        :class="!v$.form.name.$error ? '' : 'invalid'"
      >
        <i
          class="icon fa-regular fa-fw fa-user"
          :class="{
            'color--primary': !v$.form.name.$error,
            invalid: v$.form.name.$error,
          }"
        />
        <input
          v-model="v$.form.name.$model"
          type="text"
          class="form-control"
          placeholder="Insira seu nome completo"
        />
      </div>
      <!-- error message validation input : username -->
      <div
        class="input-errors mt-0"
        v-for="(error, index) of v$.form.name.$errors"
        :key="index"
      >
        <div class="error-msg">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mt-32">
      <label class="text-form mb-2 fw-400"> CPF ou CNPJ* </label>
      <div class="form-control-icon">
        <i class="icon fa-regular fa-fw fa-id-card" />
        <input
          v-maska
          data-maska="['###.###.###-##', '##.###.###/####-##']"
          v-model="model.cpfCnpj"
          type="text"
          class="form-control"
          placeholder="Insira seu CPF ou CNPJ"
          disabled
        />
      </div>
    </div>
    <div class="mt-32">
      <label class="text-form mb-2 fw-400"> E-mail*</label>
      <div class="form-control-icon">
        <i class="icon fa-regular fa-fw fa-envelope" />
        <input
          v-model="model.email"
          type="email"
          class="form-control"
          placeholder="Insira seu e-mail"
          disabled
        />
      </div>
    </div>

    <div class="mt-32">
      <label for="email" class="mb-2">Nova senha*</label>
      <div class="form-control-icon">
        <inputpassword
          v-model:modelValue="v$.form.password.$model"
          :validation="!v$.form.password.$error ? '' : 'invalid'"
          :validationIcon="{
            'color--primary': !v$.form.password.$error,
            invalid: v$.form.password.$error,
          }"
        />
        <!-- error message validation input : password -->
        <div
          class="input-errors mt-0"
          v-for="(error, index) of v$.form.password.$errors"
          :key="index"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>

    <div class="">
      <label for="email" class="mb-2">Repetir senha*</label>
      <div class="form-control-icon mb-2">
        <inputpassword
          v-model="v$.form.passwordConfirmation.$model"
          :validation="!v$.form.passwordConfirmation.$error ? '' : 'invalid'"
          :validationIcon="{
            'color--primary': !v$.form.passwordConfirmation.$error,
            invalid: v$.form.passwordConfirmation.$error,
          }"
        />
        <!-- error message validation input : password -->
        <div
          class="input-errors mt-0"
          v-for="(error, index) of v$.form.passwordConfirmation.$errors"
          :key="index"
        >
          <div class="error-msg">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="mt-32">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          v-model="acceptanceTerm"
          id="acceptanceTerm"
        />
        <label class="form-check-label" for="acceptanceTerm">
          Li e concordo com os
          <router-link
            class="color--primary"
            :to="{ name: 'termos-e-condicoes-de-uso' }"
            target="_blank"
            >Termos de uso</router-link
          >
          e
          <router-link
            class="color--primary"
            :to="{ name: 'politica-de-privacidade' }"
            target="_blank"
            >Política de privacidade</router-link
          >.
        </label>
      </div>
    </div>
    <div class="mt-32 mb-3">
      <button
        type="submit"
        v-on:click="completeRegister"
        class="btn w-100 btn-primary"
        :disabled="v$.acceptanceTerm.$invalid"
      >
        Entrar
      </button>
    </div>
  </div>

  <CustoModal
    modalName="modalSuccess"
    ref="modalSuccess"
    @success="modalSuccess"
    :config="{
      title: 'E-mail enviado!',
      cancel: 'Reenviar e-mail',
      success: 'Sim!',
      acceptButton: 'btn-primary',
      size: 'modal-custom-sm',
    }"
  >
    <div class="d-flex flex-column mb-0">
      <img
        class="mb-4"
        style="height: 120px"
        src="@/assets/img/password-notification.svg"
        alt=""
        srcset=""
      />

      <p><b>Verifique seu email e complete seu cadastro.</b></p>
      <p class="mb-0 mt-2">Você recebeu o e-mail?</p>
    </div>
  </CustoModal>

  <CustoModal
    modalName="modalError"
    ref="modalError"
    @success="modalError"
    :config="{
      title: 'Dados inválidos!',
      cancel: 'Fechar',
      success: '',
      acceptButton: 'btn-primary',
      size: 'modal-custom-sm',
    }"
  >
    <div class="d-flex flex-column mb-0">
      <img
        class="mb-4"
        style="height: 100px"
        src="@/assets/img/alert-remove.svg"
        alt=""
        srcset=""
      />

      <p><b>Ops! Não encontramos o seu cadastro no sistema.</b></p>
      <p class="mb-0 mt-2">
        Entre em contato com o administrador ou distribuidor do fundo para
        verificar seu cadastro.
      </p>
    </div>
  </CustoModal>
</template>
<script>
//Validations
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import { vMaska } from "maska";
import CustoModal from "@/components/Modal/Modal.vue";
import inputpassword from "@/components/Password/password.vue";
import shareholderService from "@/services/shareholder-service";
import { generateMessage, generateMessagev2 } from "@/utils/utils";

export default {
  directives: { maska: vMaska },
  name: "first-access",
  components: {
    CustoModal,
    inputpassword,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      model: {
        cpfCnpj: "",
        email: "",
      },
      form: {
        name: "",
        password: "",
        passwordConfirmation: "",
        token: this.token,
      },
      acceptanceTerm: false,
      token: "",
      tokenValid: false,
    };
  },
  validations() {
    return {
      form: {
        name: {
          required: helpers.withMessage(
            "É necessário inserir seu nome completo",
            required
          ),
        },
        password: {
          required: helpers.withMessage(
            "É necessário inserir uma senha",
            required
          ),
        },
        passwordConfirmation: {
          required: helpers.withMessage(
            "É necessário inserir uma senha",
            required
          ),
        },
      },
      acceptanceTerm: {
        required: helpers.withMessage(
          "É necessário concordar com o termos de uso e tolítica de privacidade. ",
          (value) => {
            return value === true;
          }
        ),
      },
    };
  },
  async mounted() {
    await this.validateToken();
  },
  methods: {
    openModal(modalName) {
      if (modalName == "modalSuccess") {
        this.$refs.modalSuccess.openModal();
      }
      if (modalName == "modalError") {
        this.$refs.modalError.openModal();
      }
    },
    async completeRegister() {
      try {
        await shareholderService.updateShareholderFirstLogin(this.form);
        this.$router.push({ name: "user-login" });
        generateMessage("SUCCESS", this, "Cadastro realizado com sucesso");
      } catch (error) {
        generateMessagev2("ERROR", this, error);
      }
    },
    async validateToken() {
      if (!this.$route.query.token) {
        this.$router.push({ name: "user-login" });
      }

      this.token = this.$route.query.token;
      try {
        let result = await shareholderService.validateShareholderFirstLogin({
          token: this.token,
        });
        this.model.cpfCnpj = result.cpfCnpj;
        this.model.email = result.email;
        this.form.token = this.token;
        this.tokenValid = true;
        this.form.name = result.name;
      } catch (error) {
        generateMessagev2("ERROR", this, error);
        this.$router.push({ name: "user-login" });
      }
    },
  },
};
</script>
<style>
.cr-main {
  width: 300px;
  margin-top: 56px;
}

.mt-32 {
  margin-top: 32px;
}
.form-control-icon > .icon-end {
  position: absolute;
  top: 30%;
  bottom: 0;
  font-size: 1rem;
  margin: auto;
  right: 1rem;
  color: var(--color-form-icon);
  z-index: 1;
  width: 1.25em;
  text-align: center;
}

.footer {
  justify-content: center;
  position: absolute;
  bottom: 0;
}

@media (max-width: 768px) {
  .cr-main {
    margin-top: auto;
  }
}
</style>
