<template>
  <div class="div-termos-politica">
    <router-link
      v-if="$route.name != 'termos-e-condicoes-de-uso'"
      :to="{ name: 'termos-e-condicoes-de-uso' }"
      target="_blank"
      >Termo de Uso&nbsp;
    </router-link>
    <template
      v-if="
        $route.name != 'termos-e-condicoes-de-uso' &&
        $route.name != 'politica-de-privacidade'
      "
      >|</template
    >
    <router-link
      v-if="$route.name != 'politica-de-privacidade'"
      :to="{ name: 'politica-de-privacidade' }"
      target="_blank"
      >&nbsp;Politica de Privacidade
    </router-link>
  </div>
</template>
<style scoped>
.div-termos-politica {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  margin-top: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
</style>
