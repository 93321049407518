<template>
  <div class="custom-search-input-main">
    <div class="custom-search-icon-search" @click="searchInput">
      <img src="@/assets/img/icon-search.svg" alt="icon search" />
    </div>
    <div class="custom-search-input">
      <input
        type="text"
        ref="inputSearch"
        :placeholder="placeholder"
        v-model="search"
        @keyup.enter="searchInput"
      />
    </div>
    <div
      v-if="researched"
      class="custom-search-icon-cancel"
      @click="cancelSearch"
    >
      <img src="@/assets/img/icon-x.svg" alt="icon cancel search" />
    </div>
  </div>
</template>
<script>
export default {
  name: "SearchInput",
  data() {
    return {
      search: "",
      researched: false,
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Pesquisar",
    },
    minSearch: {
      type: Number,
      default: 3,
    },
  },
  mounted() {
    this.search = this.value;
    if (this.search.length > 0) {
      this.searchInput();
    }
  },
  methods: {
    searchInput() {
      if (this.search.length > 0 && this.search.length >= this.minSearch) {
        this.$emit("search", this.search);
        this.researched = true;
        this.$refs.inputSearch.style.cursor = "default";
      } else {
        if (this.minSearch > 0 && this.search.length > 0) {
          this.$toast.info(
            `Digite pelo menos ${this.minSearch} caracteres para pesquisar!`
          );
        }
        this.cancelSearch();
      }
    },
    cancelSearch() {
      this.search = "";
      this.$emit("search", this.search);
      this.researched = false;
      this.$refs.inputSearch.style.cursor = "text";
      this.$refs.inputSearch.focus();
    },
  },
  watch: {
    value(value) {
      this.search = value;
      if (this.search.length > 0) {
        this.searchInput();
      }
    },
  },
};
</script>
<style scoped>
.custom-search-input-main {
  height: 38px;
  max-width: 300px;
  background-color: #ffffff;
  border: 1px solid #a7a9b6;
  border-radius: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.custom-search-icon-search {
  margin-left: 16px;
  margin-right: 8px;
  cursor: pointer;
}
.custom-search-icon-cancel {
  margin-right: 16px;
  margin-left: 8px;
  cursor: pointer;
}
.custom-search-input {
  flex: 1;
  margin-right: 16px;
}
.custom-search-input input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}
.custom-search-input input::-webkit-input-placeholder {
  color: #595b69;
}
@media screen and (max-width: 425px) {
  .custom-search-input-main {
    max-width: 100%;
  }
}
</style>
