<template>
  <div class="custom-main">
    <div>
      <CustomSide />
    </div>
    <div class="custom-container">
      <div class="custom-body">
        <p class="title mb-4">Confirme seus dados</p>
        <p class="description align-items-justify">
          Preencha os campos abaixo com os dados cadastrados no sistema.
        </p>
        <div class="col-12 mb-4">
          <label class="text-form mb-2 fw-400"> CPF ou CNPJ* </label>
          <div
            class="form-control-icon mb-2"
            :class="!v$.form.cpfCnpj.$error ? '' : 'invalid'"
          >
            <i
              class="icon fa-regular fa-fw fa-id-card"
              :class="{
                'color--primary': !v$.form.cpfCnpj.$error,
                invalid: v$.form.cpfCnpj.$error,
              }"
            />
            <input
              v-maska
              data-maska="['###.###.###-##', '##.###.###/####-##']"
              v-model="v$.form.cpfCnpj.$model"
              ref="inputcpfCnpj"
              @blur="$v.form.cpfCnpj.$touch"
              type="text"
              class="form-control"
              placeholder="Insira seu CPF ou CNPJ"
            />
          </div>
          <!-- error message validation input : username -->
          <div
            class="input-errors mt-0"
            v-for="(error, index) of v$.form.cpfCnpj.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="col-12 mb-4 mt-2">
          <label class="text-form mb-2 mt-2 fw-400"> E-mail*</label>
          <div
            class="form-control-icon mb-2"
            :class="!v$.form.email.$error ? '' : 'invalid'"
          >
            <i
              class="icon fa-regular fa-fw fa-envelope"
              :class="{
                'color--primary': !v$.form.email.$error,
                invalid: v$.form.email.$error,
              }"
            />
            <input
              v-model="v$.form.email.$model"
              type="email"
              class="form-control"
              @blur="$v.form.email.$touch"
              placeholder="Insira seu e-mail"
            />
          </div>
          <!-- error message validation input : username -->
          <div
            class="input-errors mt-0"
            v-for="(error, index) of v$.form.email.$errors"
            :key="index"
          >
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div class="col-12 mt-4">
          <!-- <button type="submit" class="btn w-100 btn-primary" :disabled="v$.form.$invalid">Entrar</button> -->
          <button
            type="submit"
            class="btn w-100 btn-primary mt-4"
            v-on:click="confirmData"
            :disabled="v$.form.$invalid"
          >
            Enviar e-mail
          </button>
        </div>
      </div>
    </div>
  </div>
  <CustoModal
    modalName="modalSuccess"
    ref="modalSuccess"
    @success="modalSuccess"
    :config="{
      title: 'E-mail enviado!',
      cancel: 'Reenviar e-mail',
      success: 'Sim!',
      acceptButton: 'btn-primary',
      size: 'modal-custom-sm',
    }"
  >
    <div class="d-flex flex-column mb-0">
      <img
        class="mb-4"
        style="height: 120px"
        src="@/assets/img/password-notification.svg"
        alt=""
        srcset=""
      />

      <p><b>Verifique seu email e complete seu cadastro.</b></p>
      <p class="mb-0 mt-2">Você recebeu o e-mail?</p>
    </div>
  </CustoModal>

  <CustoModal
    modalName="modalError"
    ref="modalError"
    @success="modalError"
    :config="{
      title: 'Dados inválidos!',
      cancel: 'Fechar',
      success: '',
      acceptButton: 'btn-primary',
      size: 'modal-custom-sm',
    }"
  >
    <div class="d-flex flex-column mb-0">
      <img
        class="mb-4"
        style="height: 100px"
        src="@/assets/img/alert-remove.svg"
        alt=""
        srcset=""
      />

      <p><b>Ops! Não encontramos o seu cadastro no sistema.</b></p>
      <p class="mb-0 mt-2">
        Entre em contato com o administrador ou distribuidor do fundo para verificar seu cadastro.
      </p>
    </div>
  </CustoModal>
</template>
<script>
//Validations
import useVuelidate from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";
import { vMaska } from "maska";
import CustoModal from "@/components/Modal/Modal.vue";
import shareholderService from "@/services/shareholder-service";
import { generateMessage } from "@/utils/utils";

export default {
  directives: { maska: vMaska },
  name: "first-access",
  components: {
    CustoModal,
  },
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      form: {
        cpfCnpj: "",
        email: "",
      },
    };
  },
  validations() {
    return {
      form: {
        cpfCnpj: {
          required: helpers.withMessage("Insira CPF ou CNPJ válidos", required),
        },
        email: {
          required: helpers.withMessage(
            "É necessário inserir um e-mail",
            required
          ),
          email: helpers.withMessage("Insira um e-mail válido", email),
        },
      },
    };
  },
  methods: {
    openModal(modalName) {
      if (modalName == "modalSuccess") {
        this.$refs.modalSuccess.openModal();
      }
      if (modalName == "modalError") {
        this.$refs.modalError.openModal();
      }
    },
    async confirmData() {
      try {
        this.form.cpfCnpj = this.$refs.inputcpfCnpj.value.replace(/\D/g, "");
        await shareholderService.firstLogin(this.form);
        this.openModal("modalSuccess");
      } catch (error) {
        this.openModal("modalError");
        this.$route.push({ name: "user-login" });
      }
    },
    modalSuccess() {
      this.$refs.modalSuccess.closeModal();
      generateMessage("Verifique seu e-mail para continuar", "SUCCESS", this);
    },
  },
};
</script>
<style>
.form-control-icon > .icon-end {
  position: absolute;
  top: 30%;
  bottom: 0;
  font-size: 1rem;
  margin: auto;
  right: 1rem;
  color: var(--color-form-icon);
  z-index: 1;
  width: 1.25em;
  text-align: center;
}

.footer {
  justify-content: center;
  position: absolute;
  bottom: 0;
}
</style>
