import axios from "axios";

import { useLoadingOnStore } from "@/store/loadingOn";

// Adiciona um interceptador na requisição
axios.interceptors.request.use(function (config) {
  // Faz alguma coisa antes da requisição ser enviada

  const loadingStore = useLoadingOnStore();
  loadingStore.onLoading(true);

  return config;
}, function (error) {
  const loadingStore = useLoadingOnStore();
  loadingStore.onLoading(false);
  // Faz alguma coisa com o erro da requisição
  return Promise.reject(error);
});

// Adiciona um interceptador na resposta
axios.interceptors.response.use(function (response) {

  // Qualquer código de status que dentro do limite de 2xx faz com que está função seja acionada
  // Faz alguma coisa com os dados de resposta
  const loadingStore = useLoadingOnStore();
  loadingStore.onLoading(false);
  return response;
}, function (error) {

  const loadingStore = useLoadingOnStore();
  loadingStore.onLoading(false);
  // Qualquer código de status que não esteja no limite do código 2xx faz com que está função seja acionada
  // Faz alguma coisa com o erro da resposta
  return Promise.reject(error);
});