import axios from "axios";

import config from "../../config";
import { useLoadingOnStore } from "@/store/loadingOn";
import authService from "./auth-service";

const URL_SERVER = config.app.baseUrl;

const instance = axios.create({
  baseURL: URL_SERVER,
});
instance.interceptors.request.use(
  async (config) => {
    var disableLoadingScreen = config.headers.disableLoadingScreen;

    if (!disableLoadingScreen) {
      const loadingStore = useLoadingOnStore();
      loadingStore.onLoading(true);
    }

    if (config.headers.sendExternalIpAddress) {
      try {
        const response = await axios.get("https://api.ipify.org?format=json");
        const externalIpAddress = response.data.ip;
        if (externalIpAddress) {
          config.headers["x-forwarded-for"] = externalIpAddress;
        }
      } catch (error) {
        console.error("Failed to get external IP address:", error);
      }
    }
    if (config.headers.dontUseBearerToken) {
      return config;
    }
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      throw new Error("token_not_found");
    }
    const token = user.access_token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    const loadingStore = useLoadingOnStore();
    loadingStore.onLoading(false);
    return Promise.reject(error);
  }
);

// Handle the error if the token is not found
instance.interceptors.response.use(
  (response) => {
    const loadingStore = useLoadingOnStore();
    loadingStore.onLoading(false);
    return response;
  },
  (error) => {
    if (error.response.status === 401 && localStorage.getItem("user")) {
      if (
        error.response.data.messages &&
        error.response.data.messages.length > 0 &&
        (error.response.data.messages.includes(
          "failed to validate token: jwt expired"
        ) ||
          error.response.data.messages.includes("jwt expired"))
      ) {
        authService.refreshToken();
      } else {
        localStorage.removeItem("user");
        window.location.href = "/";
      }
    }
    const loadingStore = useLoadingOnStore();
    loadingStore.onLoading(false);
    return Promise.reject(error);
  }
);

export default instance;
