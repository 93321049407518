import "bootstrap/dist/css/bootstrap.min.css";
import "@vuepic/vue-datepicker/dist/main.css";

import "./assets/styles/styles.css";
import Toaster from "@meforma/vue-toaster";
import { createPinia } from "pinia";
import { createGtm } from '@gtm-support/vue-gtm';
import { createApp } from "vue";
import App from "./App.vue";
import router from "./routes/router";
import translationMixin from "./services/translationMixin.js";
import config from "../config.js";
//CreateApp
const app = createApp(App);

//Plugins
app.use(Toaster);
app.use(router);
app.use(createPinia());
app.use(createGtm({
    id: config.app.idGTM,
    vueRouter: router
}));
app.mixin(translationMixin);

//Mount
app.mount("#app");

//Script
import "bootstrap/dist/js/bootstrap.min.js";
