<template>
  <div
    class="custom-accordion-main"
    v-bind:class="{
      closed: !open,
      required: required,
      [customStyle]: customStyle,
    }"
  >
    <div
      class="custom-accordion-header"
      @click="toggle"
      v-bind:class="customStyle"
    >
      <div class="custom-accordion-header-title" v-html="title"></div>
      <div class="custom-accordion-header-icon">
        <img src="@/assets/img/chevron-left.svg" />
      </div>
    </div>
    <div class="custom-accordion-body" v-bind:class="customStyle">
      <slot></slot>
    </div>
  </div>
  <div class="custom-accordion-message" v-bind:class="{ visible: required }">
    {{ message }}
  </div>
</template>
<script>
export default {
  name: "CustomAccordion",
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "Este campo é obrigatório",
    },
    title: {
      type: String,
      default: "Título",
    },
    customStyle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      open: false,
    };
  },
  methods: {
    openAccordion() {
      this.open = true;
    },
    closeAccordion() {
      this.open = false;
    },
    toggle() {
      if (this.open) {
        this.closeAccordion();
      } else {
        this.openAccordion();
      }
      this.$emit("toggle", this.open);
    },
  },
};
</script>
<style scoped>
.custom-accordion-main {
  display: flex;
  flex-direction: column;
}
.custom-accordion-message {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #dc342b;
  margin-top: 9px;
  margin-bottom: 7px;
  display: none;
}
.custom-accordion-message.visible {
  display: block;
}
.custom-accordion-header {
  min-height: 56px;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.custom-accordion-header.custom-filter-table {
  min-height: 53px;
  border: none;
  border-radius: 0px;
}
.custom-accordion-main.required .custom-accordion-header {
  border: 1px solid #dc342b;
  border-bottom: 1px solid #ebebeb;
}
.custom-accordion-main.required .custom-accordion-body {
  border: 1px solid #dc342b;
  border-top: 0px;
}
.custom-accordion-main.required.closed .custom-accordion-header {
  border-bottom: 1px solid #dc342b;
}
.custom-accordion-header-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin: 10px 16px;
  flex: 1;
}
.custom-accordion-header.custom-filter-table .custom-accordion-header-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin: 10px 20px;
}
.custom-accordion-header-icon {
  margin-right: 20px;
}
.custom-accordion-header-icon > img {
  rotate: 90deg;
  transition: 0.3s;
}
.custom-accordion-body {
  max-height: fit-content;
  background: #ffffff;
  border: 1px solid #ebebeb;
  border-top: 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  overflow: hidden;
  opacity: 1;
  transition: 0.3s, max-height 0.3s ease;
}
.custom-accordion-body.custom-filter-table {
  border: none;
  background-color: #f6f6f6;
  border-radius: 15px;
  padding: 14px 20px;
}
.custom-accordion-main.closed.custom-filter-table .custom-accordion-body {
  padding: 0px 20px;
}
.custom-accordion-main.closed .custom-accordion-header-icon > img {
  rotate: -90deg;
}
.custom-accordion-main.closed .custom-accordion-header {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.custom-accordion-main.closed.custom-filter-table {
  border-radius: 0px;
  border-bottom: 1px solid #e6e7ec;
}
.custom-accordion-main.closed.custom-filter-table:nth-last-child(2) {
  border-radius: 0px;
  border: none;
}
.custom-accordion-main.closed .custom-accordion-body {
  opacity: 0;
  max-height: 0px;
}
</style>
