<template>
  <div class="logged-out-main">
    <div class="logged-out-sidebar">
      <Sidebar :logged-out="true" ref="sidebar" />
    </div>
    <div class="logged-out-content">
      <div class="logged-out-topbar">
        <div>
          <img
            class="logged-out-topbar-img-bars"
            @click="this.$refs.sidebar.openSlidebar()"
            :src="require(`../assets/img/bars.svg`)"
          />
          <div class="logged-out-topbar-logo"></div>
        </div>
      </div>
      <div class="logged-out-header">
        <div
          v-if="this.$route.meta.previousRoute"
          class="logged-out-header-previous"
        >
          <router-link :to="{ name: this.$route.meta.previousRoute }"
            ><img :src="require(`../assets/img/chevron-left.svg`)" />
          </router-link>
        </div>
        <div class="logged-out-header-title">
          {{ this.$route.meta.title }}
        </div>
      </div>
      <div class="logged-out-body">
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import config from '../../config.js';

export default {
  name: "logged-out-layout",
  components: {
    Sidebar,
  },
  data() {
    return {
      logoClient: `url("${config.app.logo}")`,
    };
  },
};
</script>
<style scoped>
.logged-out-main {
  background-color: #f9fbfc;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.logged-out-topbar {
  z-index: 3;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #e6e7ec;
  display: flex;
  align-items: center;
  display: none;
}
.logged-out-topbar > div {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.logged-out-topbar-img-bars {
  height: 24px;
  width: 24px;
  margin-left: 24px;
  cursor: pointer;
}
.logged-out-topbar-logo {
  background-image: v-bind(logoClient);
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  height: 24px;
  margin-left: 25px;
  flex: 1;
}
.logged-out-header {
  width: 100%;
  border-bottom: 1px solid #e6e7ec;
  display: flex;
  align-items: center;
}
.logged-out-header-previous {
  margin-left: 21px;
  padding-top: 26px;
  height: 100%;
  margin-right: -8px;
}
.logged-out-header-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-left: 16px;
  padding-top: 24px;
  height: 70px;
}
.logged-out-body {
  flex: 1;
  padding: 16px;
  padding-top: 24px;
  padding-bottom: 80px;
  max-width: unset;
}
.logged-out-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e6e7ec;
  overflow-y: auto;
}
@media screen and (max-width: 768px) {
  .logged-out-content {
    border-left: none;
  }
  .logged-out-topbar {
    display: flex;
  }
  .logged-out-header {
    margin-top: 60px;
  }
}
@media screen and (min-width: 1200px) {
  .logged-out-body {
    max-width: calc(100% - 121px);
  }
}
</style>
