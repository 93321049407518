<template>
  <div class="login-main">
    <div class="login-title">Login</div>
    <div class="login-div-user">
      <div class="login-div-user-label">CPF ou CNPJ</div>
      <div class="form-control-icon">
        <i class="icon far fa-fw fa-address-card" style="color: #ff7a00" />
        <input
          ref="inputUser"
          @input="setInputUser"
          maxlength="18"
          type="tel"
          class="form-control"
          placeholder="Insira seu CPF ou CNPJ"
        />
      </div>
      <div class="msg-invalid"></div>
    </div>
    <div class="login-div-password">
      <div class="login-div-password-label">Senha</div>
      <div class="form-control-icon">
        <i class="icon far fa-fw fa-lock-keyhole" style="color: #ff7a00" />
        <input
          ref="inputPassword"
          v-model="password"
          type="password"
          class="form-control"
          placeholder="Insira sua senha"
          style="padding-right: 3rem"
          @keyup.enter="login"
        />
        <a v-if="hidePassword" class="icon-pos-right" @click="togglePassword()">
          <i class="fa-regular fa-eye"></i>
        </a>
        <a v-else class="icon-pos-right" @click="togglePassword()">
          <i class="fa-regular fa-eye-slash"></i>
        </a>
      </div>
      <div class="msg-invalid"></div>
    </div>
    <div class="login-div-recover-password">
      <router-link to="/auth/recover-password">Esqueceu a senha?</router-link>
    </div>
    <div class="login-div-button">
      <button @click="login" class="btn btn-primary">Entrar</button>
    </div>
    <div class="login-div-first-access">
      <i
        class="fa-regular fa-shield-check"
        style="color: #ff7a00; width: 11px"
      ></i>
      <router-link to="/auth/first-access">Primeiro acesso</router-link>
    </div>
  </div>
</template>
<script>
import authService from "@/services/auth-service";
import {
  generateMessage,
  getMessagesToTranslatePlugin,
  translateArray,
  handleErrorMessage,
} from "@/utils/utils";
export default {
  name: "page-login",
  data() {
    return {
      hidePassword: true,
      username: "",
      password: "",
    };
  },
  mounted() {
    localStorage.clear();
    this.$refs.inputUser.focus();
  },
  methods: {
    togglePassword() {
      this.hidePassword = !this.hidePassword;
      this.$refs.inputPassword.type = this.hidePassword ? "password" : "text";
    },
    setInputUser() {
      const value = this.$refs.inputUser.value.replace(/\D/g, "");
      this.username = value;
      if (value.length <= 11) {
        this.$refs.inputUser.value = value
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d)/, "$1.$2")
          .replace(/(\d{3})(\d{1,2})/, "$1-$2")
          .replace(/(-\d{2})\d+?$/, "$1");
      } else {
        this.$refs.inputUser.value = value
          .replace(/^(\d{2})(\d)/, "$1.$2")
          .replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
          .replace(/\.(\d{3})(\d)/, ".$1/$2")
          .replace(/(\d{4})(\d)/, "$1-$2");
      }
    },
    hideAllInvalidMessages() {
      let invalidMessages = document.querySelectorAll(".invalid");
      invalidMessages.forEach((invalidMessage) => {
        invalidMessage.classList.remove("invalid");
      });
      invalidMessages = document.querySelectorAll(".msg-invalid");
      invalidMessages.forEach((invalidMessage) => {
        invalidMessage.classList.remove("d-block");
      });
    },
    showInvalidMessage(ref, message) {
      this.$refs[ref].parentElement.classList.add("invalid");
      this.$refs[ref].parentElement.nextElementSibling.classList.add("d-block");
      this.$refs[ref].parentElement.nextElementSibling.innerHTML = message;
      this.$refs[ref].focus();
    },
    login() {
      this.hideAllInvalidMessages();
      if (
        this.username.length === 0 ||
        (this.username.length !== 11 && this.username.length !== 14)
      ) {
        this.showInvalidMessage("inputUser", "Insira um CPF ou CNPJ válido");
        return;
      }
      if (this.password.length === 0) {
        this.showInvalidMessage(
          "inputPassword",
          "É necessário inserir uma senha"
        );
        return;
      }
      if (this.password.length < 8) {
        this.showInvalidMessage(
          "inputPassword",
          "A senha deve ter no mínimo 8 dígitos"
        );
        return;
      }
      authService
        .login({
          username: this.username,
          password: this.password,
        })
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch((error) => {
          var arrKeys = getMessagesToTranslatePlugin(
            handleErrorMessage(error),
            this.$te
          );
          var arrTranslates = translateArray(arrKeys, this);
          generateMessage(arrTranslates, "ERROR", this);
        });
    },
  },
};
</script>
<style scoped>
.login-main {
  width: 300px;
}
.login-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.login-div-password,
.login-div-user {
  margin-top: 32px;
}
.login-div-password-label,
.login-div-user-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}
.icon-pos-right {
  right: 0;
  top: 0;
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-pos-right:hover {
  color: #000000;
}
.login-div-recover-password {
  margin-top: 16px;
  text-align: right;
}
.login-div-recover-password > a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ff7a00;
}
.login-div-button > button {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-top: 32px;
  border-radius: 15px;
}
.login-div-first-access {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-div-first-access > a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #ff7a00;
  margin-left: 8px;
}
.form-control:hover,
.form-select:hover {
  border-color: #000000;
}
.msg-invalid {
  display: none;
  margin-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #dc342b;
}
</style>
