import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./routes";
import AuthService from "../services/auth-service";
const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  document.querySelectorAll(".modal-backdrop").forEach((e) => e.remove());
  // Hide Tooltip
  hideTooltip();
  //Set Title
  addTitleHead(to, from);
  //Verify Autenthicator
  if (to.meta.requiresAuth && !(await AuthService.isAuthenticator())) {
    return next({ name: "user-login" });
  }
  //Verify Permissions
  userHasPermission(to, from, next);
});

function userHasPermission(to, from, next) {
  let permissions = to.meta.permissions;
  if (permissions) {
    let userHasPermission = JSON.parse(localStorage.getItem("user")).resources;
    if (userHasPermission) {
      if (permissions.some((p) => userHasPermission.includes(p))) {
        return next();
      }
    }
    return next("/access-denied");
  }
  next();
}

function hideTooltip() {
  var tooltip = document.querySelectorAll('[role="tooltip"]');
  for (let i = 0; i < tooltip.length; i++) {
    tooltip[i].remove();
  }
}

function addTitleHead(to) {
  let title = [];
  to.matched.map((item) => {
    if (Object.keys(item.meta).length > 0) {
      if (item.meta.title) {
        title.push(item.meta.title);
      }
    }
  });
  const titleJoin = title.join(" - ");
  document.title = titleJoin || "Vottar";
}

export default router;
