<template>
    <div class="row">
        <div class="col-md-12 d-flex py-5 flex-column aligin-items-center justify-content-center">
            <img class="empty-img py-3" src="../../assets/img/search-not-found.svg" alt="" srcset="">
            <p class="text-black text-center fw-normal">Não encontramos resultados para sua pesquisa.</p>
        </div>
    </div>
</template>
<script>
export default {
    name:"search-not-found"
}
</script>