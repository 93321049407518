<template>
    <div class="loading">
        <Logo class="icon" />
    </div>
</template>
<script>
import Logo from '../../components/Logo/logo.vue'
export default {
    name: "custom-loading",
    components:{
        Logo
    },
    data() {
        return {
            
        }
    }
}
</script>

<style scoped>
@keyframes slidein {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
.loading {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #0000001a;
    backdrop-filter: blur(1px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999;
    transition: all 1s ease-in;

}
.loading .icon {
    animation: slidein 2s infinite;
}
</style>