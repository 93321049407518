<template>
  <div class="create-password-main">
    <div class="create-password-title">Cadastrar senha</div>
    <div class="create-password-div-password">
      <div class="create-password-div-password-label">Nova senha*</div>
      <div class="form-control-icon">
        <i class="icon far fa-fw fa-lock-keyhole" style="color: #ff7a00" />
        <input
          ref="inputPassword"
          v-model="password"
          type="password"
          class="form-control"
          placeholder="Insira sua senha"
          style="padding-right: 3rem"
        />
        <a
          v-show="hidePassword.inputPassword"
          class="icon-pos-right"
          @click="togglePassword('inputPassword')"
        >
          <i class="fa-regular fa-eye"></i>
        </a>
        <a
          v-show="!hidePassword.inputPassword"
          class="icon-pos-right"
          @click="togglePassword('inputPassword')"
        >
          <i class="fa-regular fa-eye-slash"></i>
        </a>
      </div>
      <div class="msg-invalid"></div>
    </div>
    <div class="create-password-div-password">
      <div class="create-password-div-password-label">Repetir senha*</div>
      <div class="form-control-icon">
        <i class="icon far fa-fw fa-lock-keyhole" style="color: #ff7a00" />
        <input
          ref="inputPassword2"
          v-model="password2"
          type="password"
          class="form-control"
          placeholder="Insira sua senha"
          style="padding-right: 3rem"
        />
        <a
          v-if="hidePassword.inputPassword2"
          class="icon-pos-right"
          @click="togglePassword('inputPassword2')"
        >
          <i class="fa-regular fa-eye"></i>
        </a>
        <a
          v-else
          class="icon-pos-right"
          @click="togglePassword('inputPassword2')"
        >
          <i class="fa-regular fa-eye-slash"></i>
        </a>
      </div>
      <div class="msg-invalid"></div>
    </div>
    <div class="create-password-div-button">
      <button @click="registerPassword" class="btn btn-primary">
        Cadastrar
      </button>
    </div>
  </div>
</template>
<script>
import authService from "@/services/auth-service";
import { generateMessage } from "@/utils/utils";
export default {
  name: "page-create-password",
  data() {
    return {
      hidePassword: {
        inputPassword: true,
        inputPassword2: true,
      },
      password: "",
      password2: "",
      token: "",
    };
  },
  mounted() {
    this.getToken();
  },
  methods: {
    getToken() {
      if (!this.$route.query.token) {
        generateMessage("Link expirou ou inválido", "INFO", this);
        this.$router.push({ name: "user-login" });
      } else {
        this.token = this.$route.query.token;
      }
    },
    togglePassword(ref) {
      this.hidePassword[ref] = !this.hidePassword[ref];
      this.$refs[ref].type = this.hidePassword[ref] ? "password" : "text";
      this.$refs[ref].focus();
    },
    hideAllInvalidMessages() {
      let invalidMessages = document.querySelectorAll(".invalid");
      invalidMessages.forEach((invalidMessage) => {
        invalidMessage.classList.remove("invalid");
      });
      invalidMessages = document.querySelectorAll(".msg-invalid");
      invalidMessages.forEach((invalidMessage) => {
        invalidMessage.classList.remove("d-block");
      });
    },
    showInvalidMessage(ref, message) {
      this.$refs[ref].parentElement.classList.add("invalid");
      this.$refs[ref].parentElement.nextElementSibling.classList.add("d-block");
      this.$refs[ref].parentElement.nextElementSibling.innerHTML = message;
      this.$refs[ref].focus();
    },
    registerPassword() {
      this.hideAllInvalidMessages();
      if (this.password.length === 0) {
        this.showInvalidMessage(
          "inputPassword",
          "É necessário inserir uma senha"
        );
        return;
      }
      if (this.password.length < 8) {
        this.showInvalidMessage(
          "inputPassword",
          "A senha deve ter no mínimo 8 dígitos"
        );
        return;
      }
      if (this.password2.length === 0) {
        this.showInvalidMessage(
          "inputPassword2",
          "É necessário inserir uma senha"
        );
        return;
      }
      if (this.password2.length < 8) {
        this.showInvalidMessage(
          "inputPassword2",
          "A senha deve ter no mínimo 8 dígitos"
        );
        return;
      }
      if (this.password !== this.password2) {
        this.showInvalidMessage("inputPassword2", "As senhas não conferem");
        return;
      }
      authService.registerPassword(
        {
          password: this.password,
          passwordConfirmation: this.password2,
          token: this.token,
        },
        this
      );
    },
  },
};
</script>
<style scoped>
.create-password-main {
  width: 300px;
}
.create-password-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}
.create-password-div-password {
  margin-top: 32px;
}
.create-password-div-password-label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 8px;
}
.icon-pos-right {
  right: 0;
  top: 0;
  margin-right: 10px;
  cursor: pointer;
  position: absolute;
  width: 40px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-pos-right:hover {
  color: #000000;
}
.create-password-div-button > button {
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  margin-top: 32px;
  border-radius: 15px;
}
.form-control:hover,
.form-select:hover {
  border-color: #000000;
}
.msg-invalid {
  display: none;
  margin-top: 8px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #dc342b;
}
</style>
