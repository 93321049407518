<template>
  <div
    class="sidebar-backdrop"
    v-bind:class="{ mobile, closed }"
    @click="closed = true"
  ></div>
  <div class="sidebar-main" v-bind:class="{ mobile, closed }">
    <div class="sidebar-logo"></div>
    <div class="sidebar-btn-close">
      <img
        :src="require(`../../assets/img/icons-nav/fechar.svg`)"
        @click="closed = true"
      />
    </div>
    <div class="sidebar-ul">
      <div
        v-for="option in optionsMenu"
        :key="option.id"
        class="sidebar-li"
        v-bind:class="{ selected: option.currentRoute }"
      >
        <router-link :to="{ name: option.routeName }">
          <div v-if="option.icone" class="sidebar-li-icon">
            <img
              :src="require(`../../assets/img/icons-nav/${option.icone}.svg`)"
            />
          </div>
          <div class="sidebar-li-name">{{ option.name }}</div>
        </router-link>
      </div>
      <div v-if="!loggedOut" class="sidebar-li sidebar-btn-bottom">
        <a @click="logout">
          <div class="sidebar-li-icon">
            <img :src="require(`../../assets/img/icons-nav/sair.svg`)" />
          </div>
          <div class="sidebar-li-name">Sair</div>
        </a>
      </div>
      <div v-if="loggedOut" class="sidebar-li sidebar-btn-bottom">
        <a @click="login">
          <div class="sidebar-li-icon">
            <img :src="require(`../../assets/img/icons-nav/entrar.svg`)" />
          </div>
          <div class="sidebar-li-name">Entrar</div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import authService from "@/services/auth-service";
import { isArray } from "@vue/shared";
import options from "./menu-options.json";
import config from '../../../config.js';

export default {
  name: "custom-sidebar",
  props: {
    loggedOut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mobile: false,
      optionsMenu: options,
      closed: false,
      userPermissions: [],
      logoClient: `url("${config.app.logo}")`,
    };
  },
  mounted() {
    this.getUserPermissions();
    this.startOptions();
    this.mobile = window.innerWidth <= 768;
    window.addEventListener("resize", () => {
      this.mobile = window.innerWidth <= 768;
      if (this.mobile) {
        this.closed = true;
      }
    });
    if (this.mobile) {
      this.closed = true;
    }
    this.$router.afterEach(() => {
      this.startOptions();
      this.closed = true;
    });
  },
  methods: {
    getUserPermissions() {
      this.userPermissions = authService.getRolesUser();
      if (!this.userPermissions) {
        this.userPermissions = [];
      }
    },
    startOptions() {
      if (this.loggedOut) {
        this.optionsMenu = this.optionsMenu.filter(
          (option) => option.loggedOut
        );
      } else {
        this.optionsMenu = this.optionsMenu.filter(
          (option) => !option.loggedOut
        );
        this.optionsMenu = this.optionsMenu.filter((option) => {
          if (
            option.permissions &&
            isArray(option.permissions) &&
            option.permissions.length > 0
          ) {
            return option.permissions.some((role) =>
              this.userPermissions.includes(role)
            );
          } else {
            return true;
          }
        });
      }
      this.optionsMenu.forEach((option) => {
        option.currentRoute = this.$route.matched.some(
          (route) => route.name === option.routeName
        );
      });
    },
    logout() {
      if (authService.logout()) {
        this.$router.push({ name: "auth" });
      }
    },
    login() {
      this.$router.push({ name: "user-login" });
    },
    openSlidebar() {
      this.closed = false;
    },
  },
};
</script>
<style scoped>
.sidebar-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: none;
  transition: opacity 0.2s ease-in-out;
}
.sidebar-backdrop.mobile {
  z-index: 4;
  display: block;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.sidebar-backdrop.mobile.closed {
  visibility: hidden;
  opacity: 0;
}
.sidebar-main {
  background-color: #ffffff;
  width: 211px;
  height: 100%;
  display: flex;
  flex-direction: column;
  transition: width 0.5s ease-in-out, margin-left 0.5s;
}
.sidebar-logo {
  background-image: v-bind(logoClient);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 24px;
  margin-top: 32px;
}
.sidebar-ul {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
.sidebar-li {
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left: 16px;
  margin-right: 16px;
}
.sidebar-li > a {
  border-radius: 15px;
  min-height: 36px;
  display: flex;
  align-items: center;
  width: 100%;
}
.sidebar-li-icon {
  height: 18px;
  width: 18px;
  margin-left: 12px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sidebar-li-name {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.sidebar-li.selected {
  background: #e6e7ec;
}
.sidebar-li.selected .sidebar-li-name {
  font-weight: 600;
}
.sidebar-li:hover {
  background: #e6e7ec;
}
.sidebar-btn-bottom {
  margin-top: auto;
  margin-bottom: 32px;
  cursor: pointer;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.sidebar-btn-close {
  display: none;
  height: 64px;
}
.sidebar-btn-close > img {
  height: 16px;
  width: 16px;
  margin-left: 24px;
  cursor: pointer;
}
.sidebar-main.mobile {
  z-index: 5;
  width: 100%;
  max-width: 425px;
  position: fixed;
  box-shadow: 0.1em 0.1em 0.3em 0.1em rgb(0 0 0 / 15%);
  transition: width 0.5s ease-in-out, margin-left 0.5s;
}
.sidebar-main.mobile.closed {
  margin-left: -435px;
}
.sidebar-main.mobile > .sidebar-btn-close {
  display: flex;
  align-items: center;
}
.sidebar-main.mobile > .sidebar-logo {
  display: none;
}
.sidebar-main.mobile > .sidebar-ul {
  margin-top: 9px;
}
.sidebar-main.mobile > .sidebar-ul > .sidebar-li {
  margin-top: 16px;
}
.sidebar-main.mobile > .sidebar-ul > .sidebar-li > a {
  min-height: 48px;
}
.sidebar-main.mobile > .sidebar-ul > .sidebar-li.sidebar-btn-bottom {
  margin-top: auto;
}
</style>
