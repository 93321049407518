<template>
    <div class="modal fade" data-bs-keyboard="false" data-bs-backdrop="static" tabindex="-1" :ref="modalName">
        <div class="modal-dialog modal-custom modal-dialog-centered" :class="[config.size]">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title title-modal">{{ config.title }}</h5>
                    <button type="button" class="btn-close" @click="closeModal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <button v-if="rejectButton" @click="reject" type="button" class="btn btn-secondary mx-4">{{ reject
                    }}</button>
                    <button v-else @click="closeModal" type="button" class="btn btn-secondary"
                        :class="config.success != '' ? ' mx-4' : ''">{{ config.cancel }}</button>
                    <button v-if="config.success != ''" @click="success" type="button" class="btn" :disabled="modelVerify"
                        :class="[config.acceptButton]">{{ config.success }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Modal } from 'bootstrap';
export default {
    name: "modal-custom",
    data() {
        return {
            modal: null
        }
    },
    props: {
        modalName: String,
        config: {
            default() { return { title: 'Modal', cancel: 'Não', success: 'Sim', acceptButton: "btn-primary", size: 'modal-custom-sm' } },
            type: Object
        },
        modelVerify: {
            default: false,
            type: Boolean
        },
        rejectButton: String
    },

    mounted() {
        var modal = this.$refs[this.modalName];
        this.modal = new Modal(modal);

    },
    methods: {
        closeModal() {
            this.modal.hide()
        },
        openModal() {
            this.modal.show();
        },
        success() {
            this.$emit('success');
        },
        reject() {
            this.$emit('reject')
        }
    }

}
</script>