import { defineStore } from 'pinia';

export const useLoadingOnStore = defineStore({
    id: "loadingOn",
    state: () => ({isLoading:false}),
    getters: {
        getLoading(){
            return this.isLoading
        }
    },
    actions: {
        async onLoading(onLoading){
            this.isLoading = onLoading;
        }
    }
})