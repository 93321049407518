import api from "./api";
import config from "../../config";

const BASE_URL = config.app.baseUrl;
const MICRO_SERVICE = BASE_URL + "/fundos-cotistas";

class AnnouncementService {
  async getAll(queryparams) {
    return api
      .get(`${MICRO_SERVICE}/api/communicate`, { params: queryparams })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }

  async getById(id, idFund) {
    return api
      .get(`${MICRO_SERVICE}/api/communicate/${id}/fund/${idFund}`)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default new AnnouncementService();
