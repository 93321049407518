<template>
  <div class="custom-table-header">
    <slot></slot>
    <div
      v-if="itemsList.length > 0"
      class="custom-table-mode"
      v-bind:class="{ hide: !showModeTable }"
      @click="toggleMode"
    >
      <div
        class="custom-table-mode-button-selected"
        v-bind:class="{ right: modeTable === 'list' }"
      ></div>
      <div class="custom-table-mode-button">
        <i class="fa-regular fa-grid-2"></i>
      </div>
      <div class="custom-table-mode-button">
        <i class="fa-regular fa-list"></i>
      </div>
    </div>
  </div>
  <SearchNotFound v-if="itemsList.length === 0" />
  <div v-else-if="modeTable === 'list'" class="custom-table">
    <table class="table">
      <thead>
        <tr>
          <th
            v-for="(header, i) in headersList"
            :key="generateKey(i, 'th')"
            scope="col"
            :class="header.class"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in itemsList" :key="generateKey(i, 'tr')">
          <td
            v-for="(header, j) in headersList"
            :key="generateKey(j, 'td')"
            :class="header.class"
          >
            <div
              v-if="header.tooltip"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              :data-bs-title="item[header.value]"
              class="custom-table-td-tooltip"
              @dblclick="copyToClipboard(item[header.value])"
            />
            <div v-if="header.type === 'action'" class="custom-table-td-action">
              <div
                v-for="(action, k) in item[header.value]"
                :key="generateKey(k, 'div-icon')"
                @click="() => action.onClick(item)"
              >
                <i
                  :class="action.icon"
                  :style="{ color: action.color, cursor: 'pointer' }"
                  data-bs-toggle="tooltip"
                  data-bs-placement="bottom"
                  :data-bs-title="action.title"
                />
              </div>
            </div>
            <div
              v-else-if="header.type === 'icon'"
              class="custom-table-td-icon"
            >
              <i
                v-if="item[header.value]?.icon"
                :class="item[header.value]?.icon"
                :style="{ color: item[header.value]?.color }"
              />
              <div class="custom-table-td-text">
                {{ item[header.value]?.text }}
              </div>
            </div>
            <div
              v-else
              class="custom-table-td-text"
              v-html="
                item[`${header.value}-${header.type}`] || item[header.value]
              "
            ></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-else-if="modeTable === 'card'" class="custom-table-card-main">
    <div
      v-for="(item, i) in itemsList"
      :key="generateKey(i, 'div-card')"
      class="custom-table-card"
    >
      <div
        v-for="(header, j) in headersList"
        :key="generateKey(j, 'div-card-group')"
        class="custom-table-card-group"
      >
        <div
          v-if="header.tooltip"
          data-bs-toggle="tooltip"
          data-bs-placement="bottom"
          :data-bs-title="item[header.value]"
          class="custom-table-card-tooltip"
          @dblclick="copyToClipboard(item[header.value])"
        />
        <div v-if="header.type === 'action'" class="custom-table-card-action">
          <div class="custom-table-card-title">{{ header.text }}:</div>
          <div class="custom-table-card-value">
            <div
              v-for="(action, k) in item[header.value]"
              :key="generateKey(k, 'div-card-icon')"
              @click="() => action.onClick(item)"
            >
              <i
                :class="action.icon"
                :style="{ color: action.color, cursor: 'pointer' }"
                data-bs-toggle="tooltip"
                data-bs-placement="bottom"
                :data-bs-title="action.title"
              />
            </div>
          </div>
        </div>
        <div v-else-if="header.type === 'icon'">
          <div class="custom-table-card-title">{{ header.text }}:</div>
          <div class="custom-table-card-icon">
            <i
              v-if="item[header.value]?.icon"
              :class="item[header.value]?.icon"
              :style="{ color: item[header.value]?.color }"
            />
            <div>
              {{ item[header.value]?.text }}
            </div>
          </div>
        </div>
        <div v-else>
          <div class="custom-table-card-title">{{ header.text }}:</div>
          <div
            class="custom-table-card-value"
            v-html="
              item[`${header.value}-${header.type}`] || item[header.value]
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SearchNotFound from "@/components/SearchNotFound/SearchNotFound.vue";
export default {
  name: "custom-table",
  components: {
    SearchNotFound,
  },
  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      headersList: [],
      itemsList: [],
      modeTable: "list",
      mobile: false,
      showModeTable: false,
    };
  },
  methods: {
    formatDateTimeISO(value) {
      return new Date(value)
        .toLocaleString("pt-BR", {
          timeZone: "America/Sao_Paulo",
        })
        .replace(/:\d{2}$/, "")
        .replace(",", "");
    },
    formatDateISO(value) {
      return new Date(value)
        .toLocaleString("pt-BR", {
          timeZone: "America/Sao_Paulo",
        })
        .replace(/:\d{2}$/, "")
        .replace(",", "")
        .split(" ")[0];
    },
    formatDateTime(value) {
      const [date, time] = value.split(" ");
      const [year, month, day] = date.split("-");
      const [hour, minute] = time.split(":");
      return `${day}/${month}/${year} ${hour}:${minute}`;
    },
    formatDate(value) {
      const [year, month, day] = value.split("-");
      return `${day}/${month}/${year}`;
    },
    prepareHeaders() {
      this.headersList = this.headers;
      this.headersList.forEach((header) => {
        header.class = "";
        if (!header.align) header.align = "left";
        header.class += header.align;
      });
    },
    prepareItems() {
      this.itemsList = this.items;
      this.itemsList.forEach((item) => {
        this.headersList.forEach((header) => {
          switch (header.type) {
            case "date":
              item[`${header.value}-${header.type}`] = this.formatDate(
                item[header.value]
              );
              break;
            case "datetime":
              item[`${header.value}-${header.type}`] = this.formatDateTime(
                item[header.value]
              );
              break;
            case "datetime-iso":
              item[`${header.value}-${header.type}`] = this.formatDateTimeISO(
                item[header.value]
              );
              break;
            case "date-iso":
              item[`${header.value}-${header.type}`] = this.formatDateISO(
                item[header.value]
              );
              break;
            default:
              break;
          }
        });
      });
    },
    copyToClipboard(text) {
      if (navigator.clipboard?.writeText) {
        navigator.clipboard.writeText(text);
        this.$toast.info("Copiado para a área de transferência", {
          position: "top-right",
        });
      } else {
        this.$toast.error(
          "Não foi possível copiar para a área de transferência",
          {
            position: "top-right",
          }
        );
      }
    },
    generateKey(i, type = "div") {
      return `${i}-${type}-${Math.random().toString(36).slice(2, 11)}`;
    },
    toggleMode() {
      this.modeTable = this.modeTable === "list" ? "card" : "list";
    },
  },
  created() {
    this.prepareHeaders();
    this.prepareItems();
  },
  mounted() {
    this.mobile = window.innerWidth <= 768;
    if (this.mobile) {
      this.modeTable = "card";
      this.showModeTable = false;
    } else {
      this.modeTable = "list";
      this.showModeTable = true;
    }
    window.addEventListener("resize", () => {
      this.mobile = window.innerWidth <= 768;
      if (this.mobile) {
        this.modeTable = "card";
        this.showModeTable = false;
      } else {
        this.showModeTable = true;
      }
    });
  },
  watch: {
    headers() {
      this.prepareHeaders();
    },
    items() {
      this.prepareItems();
    },
  },
};
</script>
<style scoped>
.custom-table-header {
  display: flex;
  justify-content: flex-end;
  margin-top: 12px;
  gap: 12px;
}
.custom-table-mode.hide {
  display: none;
}
.custom-table-td-action {
  display: flex;
  gap: 10px;
  align-items: inherit;
  justify-content: right;
}
.custom-table-td-icon {
  display: flex;
  gap: 10px;
  align-items: inherit;
  justify-content: right;
}
.custom-table-td-icon > svg {
  width: 16px;
  height: 16px;
}
.custom-table table thead th.left,
.custom-table table td.left {
  text-align: left;
}
.custom-table table td.left > .custom-table-td-action,
.custom-table table td.left > .custom-table-td-icon {
  justify-content: flex-start;
  align-items: center;
}
.custom-table table thead th.right,
.custom-table table td.right {
  text-align: right;
}
.custom-table table td.right > .custom-table-td-action,
.custom-table table td.right > .custom-table-td-icon {
  justify-content: flex-end;
  align-items: center;
}
.custom-table table thead th.center,
.custom-table table td.center {
  text-align: center;
}
.custom-table table td.center > .custom-table-td-action,
.custom-table table td.center > .custom-table-td-icon {
  justify-content: center;
  align-items: center;
}
.custom-table table td {
  position: relative;
}
.custom-table-td-tooltip {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.custom-table-td-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.custom-table-card-main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, auto));
  gap: 5px;
  margin-bottom: 20px;
  margin-top: 12px;
}
.custom-table-card {
  background: #ffffff;
  padding: 16px;
  border-radius: 10px;
  border: 1px solid #e6e7ec;
  flex: 1;
  width: 100%;
}
.custom-table-card:hover {
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
}
.custom-table-card-group {
  position: relative;
}
.custom-table-card-tooltip {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.custom-table-card-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.custom-table-card-value {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000000;
  margin-top: 4px;
  margin-bottom: 16px;
}
.custom-table-card-action {
  display: flex;
  justify-content: space-between;
}
.custom-table-card-action .custom-table-card-value {
  margin: 0px;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.custom-table-card-icon {
  display: flex;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 16px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
}
.custom-table-card-icon > svg {
  margin-right: 8px;
}
.custom-table-mode {
  display: flex;
  height: 38px;
  width: 76px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #e6e7ec;
  position: relative;
  z-index: 0;
}
.custom-table-mode-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50%;
  border-radius: 10px;
  cursor: pointer;
}
.custom-table-mode-button-selected {
  cursor: pointer;
  background: #ff7a00;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
  border-radius: 9px;
  position: absolute;
  z-index: -1;
  height: 100%;
  width: 50%;
  top: 0;
  left: 0;
  transition: left 0.2s ease-in-out;
}
.custom-table-mode-button-selected.right {
  left: 50%;
}

@media screen and (max-width: 768px) {
  .custom-table-card {
    width: 100%;
  }
}
</style>
