<template>
  <div class="vna-main">
    <div>
      <CustomSide />
    </div>
    <div class="vna-container">
      <div class="vna-body">
        <div class="vna-body-img">
          <img src="@/assets/img/warning-alert.svg" alt="Vote Not Available" />
        </div>
        <div class="vna-body-title">Votação indisponível!</div>
        <div class="vna-body-subtitle">
          Em caso de dúvida, entre em contato com o administrador ou distribuidor do fundo.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSide from "@/components/CustomSide/customSide.vue";
export default {
  name: "vote-not-available",
  components: {
    CustomSide,
  },
};
</script>
<style scoped>
.vna-main {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
}
.vna-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vna-body {
  width: 300px;
}
.vna-body-img {
  display: flex;
  justify-content: center;
  align-items: center;
}
.vna-body-title {
  margin-top: 32px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
  text-align: center;
}
.vna-body-subtitle {
  margin-top: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}
@media screen and (min-width: 769px) {
  .vna-main {
    flex-direction: row;
  }
  .vna-container {
    overflow-y: auto;
  }
}
@media screen and (max-width: 768px) {
  .vna-container {
    align-items: flex-start;
  }
}
</style>
