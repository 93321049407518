<template>
  <div class="status-main">
    <div>
      <CustomSide />
    </div>
    <div class="status-container">
      <div class="status-body">
        <h2 class="status-body-title mb-4">
          Health check <i class="fa-regular fa-gear fa-spin color--primary"></i>
        </h2>
        <div class="row pb-4">
          <CustomAccordion
            :title="`BACKEND`"
            class="w-100"
            customStyle="v-height-max"
            ref="status-front"
          >
            <div v-for="(item, i) in backEnd" :key="i" class="mx-2 pb-2">
              <CustomAccordion
                :title="`Service: ${item.service}`"
                customStyle="custom-filter-table"
                class="mb-3"
              >
                <div class="s-body-content my-2">
                  <b
                    ><i class="fa-regular fa-server color--primary mx-2"></i>
                    Server: </b
                  ><i
                    class="fa-regular fa-circle-dot fa-flip-vertical fa-beat-fade mx-2"
                    :class="
                      item.status.server.status
                        ? 'color--success'
                        : 'color--danger'
                    "
                  ></i
                  >{{ item.status.server.description }}
                  <div v-if="item.status.database" class="mt-2">
                    <b
                      ><i
                        class="fa-regular fa-database color--primary mx-2"
                      ></i>
                      Database: </b
                    ><i
                      class="fa-regular fa-circle-dot fa-flip-vertical fa-beat-fade mx-2"
                      :class="
                        item.status.database.status
                          ? 'color--success'
                          : 'color--danger'
                      "
                    ></i
                    >{{ item.status.database.description }}
                  </div>
                </div>
              </CustomAccordion>
            </div>
          </CustomAccordion>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSide from "@/components/CustomSide/customSide.vue";
import versionService from "@/services/version-service";
import CustomAccordion from "@/components/Custom/Accordion.vue";
import { generateMessagev2 } from "@/utils/utils";

export default {
  name: "page-status",
  components: {
    CustomSide,
    CustomAccordion,
  },
  data() {
    return {
      version: "",
      backEnd: [],
    };
  },
  methods: {
    openModal() {
      this.$refs["status-info"].openModal();
    },
    async getStatus() {
      this.$refs["status-front"].openAccordion();

      try {
        let result = await versionService.getHealthCheck();
        console.log(result);
        if (result) {
          this.backEnd = result.backEnd;
        }
      } catch (error) {
        generateMessagev2("ERROR", this, error);
      }
    },
  },
  mounted() {
    this.getStatus();
  },
};
</script>
<style scoped>
.status-main {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
}

.status-container {
  display: flex;
  align-items: left;
  padding-left: 54px;
  padding-top: 64px;
  width: 100%;
  padding-bottom: 30px;
}

.status-body {
  min-width: 90%;
  width: calc(100% - 105px);
}

.status-body-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-body-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  text-align: left;
}

.status-body-subtitle {
  margin-top: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.status-body-button {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-body-button button {
  width: 100%;
  height: 56px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border-radius: 15px;
}

.s-body-content {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  word-break: break-all;
  font-size: 14px;
}

.v-accordion-header {
  width: 595px;
}
.v-height-max {
  max-height: fit-content !important;
}
.btn-copy {
  font-size: 16px;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .status-main {
    flex-direction: row;
  }

  .status-container {
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .status-container {
    align-items: flex-start;
    padding-left: 0px;
    padding-top: 0px;
    width: 100%;
    padding-bottom: 30px;
  }

  .status-body {
    width: 90%;
    margin-left: 5%;
  }
}
</style>
