<template>
  <div class="version-main">
    <div>
      <CustomSide />
    </div>
    <div class="version-container">
      <div class="version-body">
        <h2 class="version-body-title" v-on:click="copyToClipboard()">
          Versão do site
          <i class="fa-regular fa-copy color--primary btn-copy"></i>
        </h2>
        <div class="row py-4">
          <CustomAccordion :title="`FRONTEND`" ref="version-front" class="mb-3">
            <div class="p-3 v-body-accordion">
              <b>tag: </b>"{{ frontEnd.tag }}"<br />
              <b>buildDate: </b>"{{ frontEnd.date }}"<br />
              <b>branch: </b> "{{ frontEnd.branch }}"<br />
              <b>commit: </b>"{{ frontEnd.commit }}"<br />
              <b>buildNumber: </b>"{{ frontEnd.build }}"<br />
              <b>repo: </b>"{{ frontEnd.repo }}"<br />
            </div>
          </CustomAccordion>
        </div>
        <div class="row pb-4">
          <CustomAccordion
            :title="`BACKEND`"
            class="w-100"
            customStyle="v-height-max"
          >
            <div v-for="(item, i) in backEnd" :key="i" class="mx-2 pb-2">
              <CustomAccordion
                :title="`Service: ${item.repo}`"
                customStyle="custom-filter-table"
                class="mb-3"
              >
                <div class="v-body-accordion">
                  <b>tag: </b>"{{ item.tag }}"<br />
                  <b>buildDate: </b>"{{ item.buildDate }}"<br />
                  <b>branch: </b>"{{ item.branch }}"<br />
                  <b>commit: </b>"{{ item.commit }}"<br />
                  <b>buildNumber: </b>"{{ item.buildNumber }}"<br />
                  <b>repo: </b>"{{ item.repo }}"<br />
                </div>
              </CustomAccordion>
            </div>
          </CustomAccordion>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CustomSide from "@/components/CustomSide/customSide.vue";
import versionService from "@/services/version-service";
import CustomAccordion from "@/components/Custom/Accordion.vue";
import { generateMessagev2 } from "@/utils/utils";

export default {
  name: "page-version",
  components: {
    CustomSide,
    CustomAccordion,
  },
  data() {
    return {
      version: "",
      frontEnd: {
        tag: "",
        buildDate: "",
        branch: "",
        commit: "",
        buildNumber: "",
        repo: "",
      },
      backEnd: [],
    };
  },
  methods: {
    openModal() {
      this.$refs["version-info"].openModal();
    },
    async getVersion() {
      this.$refs["version-front"].openAccordion();

      try {
        let result = await versionService.getVersion();

        if (result) {
          this.frontEnd = result.frontEnd;
          this.backEnd = result.backEnd;
        }
      } catch (error) {
        generateMessagev2("ERROR", this, error);
      }
    },
    copyToClipboard() {
      let text = JSON.stringify({
        frontEnd: this.frontEnd,
        backEnd: this.backEnd,
      });
      if (navigator.clipboard?.writeText) {
        navigator.clipboard.writeText(text);
        this.$toast.info("Copiado para a área de transferência", {
          position: "top-right",
        });
      } else {
        this.$toast.error(
          "Não foi possível copiar para a área de transferência",
          {
            position: "top-right",
          }
        );
      }
    },
  },
  mounted() {
    this.getVersion();
  },
};
</script>
<style scoped>
.version-main {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  height: 100vh;
}

.version-container {
  display: flex;
  align-items: left;
  padding-left: 54px;
  padding-top: 64px;
  width: 100%;
  padding-bottom: 30px;
}

.version-body {
  min-width: 90%;
  width: calc(100% - 105px);
}

.version-body-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.version-body-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 36px;
  color: #000000;
  text-align: left;
}

.version-body-subtitle {
  margin-top: 24px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.version-body-button {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.version-body-button button {
  width: 100%;
  height: 56px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  border-radius: 15px;
}

.v-body-accordion {
  word-break: break-all;
  font-size: 12px;
}

.v-accordion-header {
  width: 595px;
}
.v-height-max {
  max-height: fit-content !important;
}
.btn-copy {
  font-size: 16px;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .version-main {
    flex-direction: row;
  }

  .version-container {
    overflow-y: auto;
  }
}

@media screen and (max-width: 768px) {
  .version-container {
    align-items: flex-start;
    padding-left: 0px;
    padding-top: 0px;
    width: 100%;
    padding-bottom: 30px;
  }

  .version-body {
    width: 90%;
    margin-left: 5%;
  }
}
</style>
