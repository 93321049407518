<template>
  <div class="dashboard-main">
    <div class="dashboard-sidebar">
      <Sidebar ref="sidebar" />
    </div>
    <div class="dashboard-content">
      <div class="dashboard-topbar">
        <div>
          <img
            class="dashboard-topbar-img-bars"
            @click="this.$refs.sidebar.openSlidebar()"
            :src="require(`../assets/img/bars.svg`)"
          />
          <div class="dashboard-topbar-logo"></div>
        </div>
      </div>
      <div class="dashboard-header">
        <div
          v-if="this.$route.meta.previousRoute"
          class="dashboard-header-previous"
        >
          <router-link :to="{ name: this.$route.meta.previousRoute }"
            ><img :src="require(`../assets/img/chevron-left.svg`)" />
          </router-link>
        </div>
        <div class="dashboard-header-title">
          {{ title }}
        </div>
      </div>
      <div class="dashboard-body">
        <router-view v-slot="{ Component }">
          <transition name="fade">
            <component :is="Component" @title-changed="title = $event" />
          </transition>
        </router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import config from '../../config.js';

export default {
  name: "dashboard-layout",
  components: {
    Sidebar,
  },
  data() {
    return {
      title: this.$route.meta.title,
      logoClient: `url("${config.app.logo}")`,
    };
  },
  watch: {
    $route(to) {
      this.title = to.meta.title;
    },
  },
};
</script>
<style scoped>
.dashboard-main {
  background-color: #f9fbfc;
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100%;
}
.dashboard-topbar {
  z-index: 3;
  position: fixed;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #e6e7ec;
  display: flex;
  align-items: center;
  display: none;
}
.dashboard-topbar > div {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
.dashboard-topbar-img-bars {
  height: 24px;
  width: 24px;
  margin-left: 24px;
  cursor: pointer;
}
.dashboard-topbar-logo {
  background-image: v-bind(logoClient);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 24px;
  margin-left: -48px;
  flex: 1;
  z-index: -1;
}
.dashboard-header {
  width: 100%;
  border-bottom: 1px solid #e6e7ec;
  display: flex;
  align-items: center;
}
.dashboard-header-previous {
  margin-left: 21px;
  padding-top: 26px;
  height: 100%;
  margin-right: -8px;
}
.dashboard-header-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  margin-left: 16px;
  padding-top: 24px;
  height: 70px;
}
.dashboard-body {
  flex: 1;
  padding: 16px;
  padding-top: 24px;
  padding-bottom: 80px;
  max-width: unset;
}
.dashboard-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #e6e7ec;
  overflow-y: auto;
}
@media screen and (max-width: 768px) {
  .dashboard-content {
    border-left: none;
  }
  .dashboard-topbar {
    display: flex;
  }
  .dashboard-header {
    margin-top: 60px;
  }
}
@media screen and (min-width: 1200px) {
  .dashboard-body {
    max-width: calc(100% - 121px);
  }
}
</style>
